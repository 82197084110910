import loadable from '@loadable/component';
import { LoadingScreen } from 'components';

const Dashboard = loadable(
  () => import(/* webpackChunkName: "DashboardPage" */ 'pages/supply/farmers/list'),
  {
    fallback: <LoadingScreen />,
  },
);

export default function () {
  return <Dashboard />;
}
