import { CanParams, CanReturnType } from '@pankod/refine-core';
import { ENV } from '@config/env';
import { FEATURE } from '@config/feature';
import { MODULES } from '@config/modules';
import { envModules } from 'libs/helpers/uac';
import { axiosInstance } from 'libs/utils/axiosInstance';
import { auth } from './authProvider';
import { SERVICE } from '@config/service';
import { MemoryAdapter, newEnforcer } from 'casbin.js';
import { model } from '@config/model';
import { permissionsToString } from '@libs/utils';

const authServiceUrl = `${SERVICE.AUTH}/v2`;

export const accessControl = {
  can: async ({ action, resource }: CanParams): Promise<CanReturnType> => {
    const isAuthenticated = auth.isSignedAsLogged();
    let accessToken;
    let profile;

    try {
      accessToken = await auth.getAccessToken();
      profile = await auth.getProfile(accessToken as string);
    } catch (error) {
      return { can: false, reason: 'Failed to fetch profile or access token' };
    }

    const subject = profile?.data?.email;

    if (!FEATURE.ENABLE_FEATURE_RBAC) {
      if (!envModules(MODULES).includes(resource) || !isAuthenticated || !accessToken) {
        return { can: false, reason: 'Unauthorized' };
      }
      return { can: true };
    }

    try {
      const resp = await axiosInstance.post<{ data: { eligible: boolean } }>(
        `${authServiceUrl}/rbac/enforce`,
        {
          resource,
          action,
          domain: ENV.APP_ID,
          user: subject,
        },
      );

      return {
        can: resp.data.data.eligible,
      };
    } catch (error: unknown) {
      if (error instanceof Error) {
        return { can: false, reason: error.message };
      }

      throw error;
    }
  },
};

export const localStrategy = {
  can: async ({ resource, action }: CanParams): Promise<CanReturnType> => {
    const accessToken = await auth.getAccessToken();

    if (!accessToken) {
      return {
        can: false,
        reason: 'Unauthorized',
      };
    }

    const profile = await auth.getProfile(accessToken);
    const permission = permissionsToString(profile?.data?.permissions);
    const adapter = new MemoryAdapter(permission);
    const subject = profile?.data?.email;
    const domain = ENV.APP_ID.toLowerCase();

    const enforcer = await newEnforcer(model, adapter);
    const can = await enforcer.enforce(subject, domain, resource, action);

    return Promise.resolve({ can });
  },
};
