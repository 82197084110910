import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';
import { LoadingScreen } from 'components';

/* PLOP_INJECT_LOADABLE */
const PromoList = loadable(
  () => import(/* webpackChunkName: "PromoList" */ 'pages/campaign/promo/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const PromoCreate = loadable(
  () => import(/* webpackChunkName: "PromoCreate" */ 'pages/campaign/promo/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const PromoEdit = loadable(
  () => import(/* webpackChunkName: "PromoEdit" */ 'pages/campaign/promo/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const PromoShow = loadable(
  () => import(/* webpackChunkName: "PromoShow" */ 'pages/campaign/promo/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignBannerCreate = loadable(
  () => import(/* webpackChunkName: "CampaignBannerCreate" */ 'pages/campaign/banner/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignBannerEdit = loadable(
  () => import(/* webpackChunkName: "CampaignBannerEdit" */ 'pages/campaign/banner/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignBannerList = loadable(
  () => import(/* webpackChunkName: "CampaignBannerList" */ 'pages/campaign/banner/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignBannerShow = loadable(
  () => import(/* webpackChunkName: "CampaignBannerShow" */ 'pages/campaign/banner/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignBudgetCreate = loadable(
  () => import(/* webpackChunkName: "CampaignBudgetCreate" */ 'pages/campaign/budget/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignPromoFarmerCreate = loadable(
  () =>
    import(/* webpackChunkName: "CampaignPromoFarmerCreate" */ 'pages/campaign/promoFarmer/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignPromoFarmerEdit = loadable(
  () => import(/* webpackChunkName: "CampaignPromoFarmerEdit" */ 'pages/campaign/promoFarmer/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignPromoFarmerList = loadable(
  () => import(/* webpackChunkName: "CampaignPromoFarmerList" */ 'pages/campaign/promoFarmer/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignPromoFarmerShow = loadable(
  () => import(/* webpackChunkName: "CampaignPromoFarmerShow" */ 'pages/campaign/promoFarmer/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const CampaignPromoBuyerCreate = loadable(
  () =>
    import(/* webpackChunkName: "CampaignPromoBuyerCreate" */ 'pages/campaign/promoBuyer/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignPromoBuyerEdit = loadable(
  () => import(/* webpackChunkName: "CampaignPromoBuyerEdit" */ 'pages/campaign/promoBuyer/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignPromoBuyerList = loadable(
  () => import(/* webpackChunkName: "CampaignPromoBuyerList" */ 'pages/campaign/promoBuyer/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const CampaignPromoBuyerShow = loadable(
  () => import(/* webpackChunkName: "CampaignPromoBuyerShow" */ 'pages/campaign/promoBuyer/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const Percent = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Percent'));

export const campaignResources: ResourceProps[] = [
  {
    name: 'campaign',
    icon: <Percent size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'campaign_campaigns',
    parentName: 'campaign',
    list: PromoList,
    create: PromoCreate,
    edit: PromoEdit,
    show: PromoShow,
    options: {
      label: 'promo',
      route: 'promo',
    },
  },
  {
    name: 'campaign_campaign-lines',
    parentName: 'campaign',
    create: CampaignBannerCreate,
    edit: CampaignBannerEdit,
    list: CampaignBannerList,
    show: CampaignBannerShow,
    canDelete: true,
    options: {
      label: 'banner',
      route: 'banner',
    },
  },
  {
    name: 'campaign_budget',
    parentName: 'campaign',
    list: CampaignBudgetCreate,
    options: {
      label: 'Budget',
      route: 'budget',
    },
  },
  {
    name: 'campaign_promo_farmer',
    parentName: 'campaign',
    create: CampaignPromoFarmerCreate,
    edit: CampaignPromoFarmerEdit,
    list: CampaignPromoFarmerList,
    show: CampaignPromoFarmerShow,
    canDelete: true,
    options: {
      label: 'Promo farmer',
      route: 'promo_farmer',
    },
  },
  {
    name: 'campaign_promo-buyer',
    parentName: 'campaign',
    create: CampaignPromoBuyerCreate,
    edit: CampaignPromoBuyerEdit,
    list: CampaignPromoBuyerList,
    show: CampaignPromoBuyerShow,
    options: {
      label: 'Promo buyer',
      route: 'promo-buyer',
    },
  },
].filter(envModuleFlagVault);
