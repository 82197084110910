export const SERVICE = {
  FISH: process.env.REACT_APP_SERVICE_FISH || '',
  FISH_V2: process.env.REACT_APP_SERVICE_FISH_V2 || '',
  FILE: process.env.REACT_APP_SERVICE_FILE || '',
  SUPPORT: process.env.REACT_APP_SERVICE_SUPPORT || '',
  SUPPORT_INTER: process.env.REACT_APP_SERVICE_SUPPORT_INTER || '',
  CUSTOMER: process.env.REACT_APP_SERVICE_CUSTOMER || '',
  CUSTOMER_INTER: process.env.REACT_APP_SERVICE_CUSTOMER_INTER || '',
  FRESH: process.env.REACT_APP_SERVICE_FRESH || '',
  CRM: process.env.REACT_APP_SERVICE_CRM || '',
  AUTH: process.env.REACT_APP_SERVICE_AUTH || '',
  PROMO: process.env.REACT_APP_SERVICE_PROMO || '',
  PROMO_V2: process.env.REACT_APP_SERVICE_PROMO_V2 || '',
  AUTH2: process.env.REACT_APP_SERVICE_AUTH2 || '',
  B2B: process.env.REACT_APP_SERVICE_B2B || '',
  PAYMENT: process.env.REACT_APP_SERVICE_PAYMENT || '',
  FLAGGING: process.env.REACT_APP_SERVICES_FLAGGING || '',
  PMS: process.env.REACT_APP_SERVICE_PMS || '',
  SKU: process.env.REACT_APP_SERVICE_SKU || '',
  OPS_VENDOR: process.env.REACT_APP_SERVICE_OPS_VENDOR || '',
  SUPPLY_CHAIN: process.env.REACT_APP_SERVICE_SUPPLY_CHAIN || '',
  WMS: process.env.REACT_APP_SERVICE_WAREHOUSE || '',
  HARVEST_SHRIMP: process.env.REACT_APP_SERVICE_HARVEST_SHRIMP || '',
};
