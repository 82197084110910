import { CSSProperties } from 'react';

export const antLayoutSider: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
};

export const antLayoutSiderMobile: CSSProperties = {
  position: 'fixed',
  height: '100vh',
  zIndex: 100,
};
