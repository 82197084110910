import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_LOADABLE */
const SystemEventLogsList = loadable(
  () => import(/* webpackChunkName: "SystemEventLogsList" */ 'pages/system/eventLogs/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const SystemEventLogsShow = loadable(
  () => import(/* webpackChunkName: "SystemEventLogsShow" */ 'pages/system/eventLogs/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const Terminal = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Terminal'));

export const systemResources: ResourceProps[] = [
  {
    name: 'system',
    icon: <Terminal size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'system_event-logs',
    parentName: 'system',
    list: SystemEventLogsList,
    show: SystemEventLogsShow,
    options: {
      label: 'Event logs',
      route: 'event-logs',
    },
  },
].filter(envModuleFlagVault);
