import { RefineRouteProps } from '@pankod/refine-react-router-v6';
import Callback from 'pages/auth/callback';

const attendanceRoutes: RefineRouteProps[] = [
  {
    element: <Callback />,
    path: '/auth/callback',
  },
];

export default attendanceRoutes;
