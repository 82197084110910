import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ErrorBoundary } from 'components';

import { FEATURE } from 'config/feature';
import { sentry } from 'libs/helpers';
import loadable from '@loadable/component';
import { detectLocale, I18nActivate, I18nProvider } from '@libs/i18n';
import { ENV } from '@config/env';
import { Stage } from '@efishery/sdk-core';
import { EfisheryProvider } from '@efishery/sdk-reactjs';

const Maintenance = loadable(
  () => import(/* webpackChunkName: "Maintenance" */ 'components/layout/maintenance'),
);

sentry.initialize();

ReactDOM.render(
  <>
    <React.StrictMode>
      <I18nActivate locale={detectLocale()} />
      <EfisheryProvider
        clientId={ENV.AUTH_CLIENT_ID}
        stage={Stage[ENV.APP_ENVIRONMENT as keyof typeof Stage]}
        enableCookieKeys={ENV.ENABLE_COOKIE_KEYS}
        redirectUrl={`${window.location.origin}/auth/callback`}
        failedRedirectUrl={`${window.location.origin}/login`}
      >
        <I18nProvider>
          {FEATURE.ENABLE_MAINTENANCE_MODE ? (
            <Maintenance />
          ) : (
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          )}
        </I18nProvider>
      </EfisheryProvider>
    </React.StrictMode>
  </>,
  document.getElementById('root'),
);
