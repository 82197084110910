import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_IMPORT */
const MerchandiserPurchaseOrderCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderCreate" */ 'pages/merchandiser/purchaseOrder/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const MerchandiserPurchaseOrderEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderEdit" */ 'pages/merchandiser/purchaseOrder/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const MerchandiserPurchaseOrderList = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderList" */ 'pages/merchandiser/purchaseOrder/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const MerchandiserPurchaseOrderShow = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderShow" */ 'pages/merchandiser/purchaseOrder/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const MerchandiserPurchaseOrderCreateV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderCreateV2" */ 'pages/merchandiser/purchaseOrderNew/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const MerchandiserPurchaseOrderEditV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderEditV2" */ 'pages/merchandiser/purchaseOrderNew/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const MerchandiserPurchaseOrderListV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderListV2" */ 'pages/merchandiser/purchaseOrderNew/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const MerchandiserPurchaseOrderShowV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderShowV2" */ 'pages/merchandiser/purchaseOrderNew/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const MerchandiserStockCatalogList = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserStockCatalogList" */ 'pages/merchandiser/stockCatalog/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const MerchandiserStockCatalogShow = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserStockCatalogShow" */ 'pages/merchandiser/stockCatalog/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const Storefront = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Storefront'));

export const merchandiserResources: ResourceProps[] = [
  {
    name: 'merchandiser',
    icon: <Storefront size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'merchandiser_purchase-order',
    parentName: 'merchandiser',
    create: MerchandiserPurchaseOrderCreate,
    edit: MerchandiserPurchaseOrderEdit,
    list: MerchandiserPurchaseOrderList,
    show: MerchandiserPurchaseOrderShow,
    options: {
      label: 'Pembelian',
      route: 'purchase-order-old',
    },
  },
  {
    name: 'merchandiser_purchase-order-v2',
    parentName: 'merchandiser',
    create: MerchandiserPurchaseOrderCreateV2,
    edit: MerchandiserPurchaseOrderEditV2,
    list: MerchandiserPurchaseOrderListV2,
    show: MerchandiserPurchaseOrderShowV2,
    options: {
      label: 'Pembelian',
      route: 'purchase-order',
    },
  },
  {
    name: 'merchandiser_stock-catalog',
    parentName: 'merchandiser',
    list: MerchandiserStockCatalogList,
    show: MerchandiserStockCatalogShow,
    options: {
      label: 'Katalog Stok',
      route: 'stock-catalog',
    },
  },
].filter(envModuleFlagVault);
