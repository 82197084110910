import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from '@pankod/refine-react-router-v6';
import React from 'react';
import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ENV } from 'config/env';
import { FEATURE } from 'config/feature';
import { SERVICE } from 'config/service';
import { stringToRegex } from 'libs/utils';

const initialize = () => {
  if (ENV.APP_ENVIRONMENT === 'local' || process.env.NODE_ENV !== 'production') return;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: ENV.APP_ENVIRONMENT === 'production' ? 1 : 0.5,
    sampleRate: 1,
    release: `${ENV.APP_ID}@${ENV.APP_VERSION}`.toLowerCase(),
    environment: ENV.APP_ENVIRONMENT,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
};

const initializeWithRoutingInstrument = () => {
  if (ENV.APP_ENVIRONMENT === 'local' || process.env.NODE_ENV !== 'production') return;

  const isTargetSpecified = FEATURE.ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_TARGET;

  const FE_URL = SERVICE.FISH?.split('api')?.[0] || '';
  const BE_REGEX = stringToRegex(SERVICE.FISH);

  const target = isTargetSpecified && FE_URL && BE_REGEX ? [FE_URL, BE_REGEX] : undefined;

  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
        tracePropagationTargets: target,
      }),
    ],
    tracesSampleRate: ENV.APP_ENVIRONMENT === 'production' ? 1 : 0.5,
    sampleRate: 1,
    release: `${ENV.APP_ID}@${ENV.APP_VERSION}`.toLowerCase(),
    environment: ENV.APP_ENVIRONMENT,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
};

const setUser = (user: User) => {
  const userProfile: User = {
    email: user.email || '',
    name: user.name || '',
  };
  Sentry.setUser(userProfile);
};

export const sentry = {
  initialize: FEATURE.ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_ROUTING
    ? initializeWithRoutingInstrument
    : initialize,
  setUser,
};
