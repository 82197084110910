import React, { useEffect } from 'react';
import { i18n } from '@lingui/core';
import { detect, fromStorage, fromUrl } from '@lingui/detect-locale';
import { I18nProvider, I18nProviderProps } from '@lingui/react';
import { defaultLocale, detectLocaleKey, supportedLocales } from '@constants/i18n';

const isBrowser = typeof window !== 'undefined';

type GuardApply = <T extends (...args: any[]) => any>(
  fn: T,
  defaultValue: ReturnType<T>,
  ...args: Parameters<T>
) => ReturnType<T>;

const runOnBrowser: GuardApply = (fn, defaultValue, ...args) =>
  isBrowser ? fn(...args) : defaultValue;

const toStorage = (locale: string) =>
  isBrowser &&
  Promise.resolve()
    .then(() => window.localStorage.setItem(detectLocaleKey, locale))
    .catch((err) => {
      console.error(err);
    });

const detectLocale = () =>
  detect(
    runOnBrowser(fromUrl, defaultLocale, detectLocaleKey),
    runOnBrowser(fromStorage, defaultLocale, detectLocaleKey),
  ) || defaultLocale;

const i18nActivate = async (locale: string) => {
  const { messages } = await import(`../../locales/${locale}.ts`);
  i18n.loadAndActivate({ locale, messages });
  toStorage(locale);
};

const I18nActivate = ({ locale }: { locale: string }) => {
  useEffect(() => {
    i18nActivate(locale);
  }, [locale]);
  return null;
};

const Provider = (props: Omit<I18nProviderProps, 'i18n'>) => {
  return <I18nProvider {...props} i18n={i18n} />;
};

export {
  defaultLocale,
  detectLocale,
  detectLocaleKey,
  I18nActivate,
  i18nActivate,
  Provider as I18nProvider,
  supportedLocales,
};
