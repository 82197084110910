import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_IMPORT */

// Driver Management
const LogisticDriverManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDriverManagementList" */ 'pages/logistic/driverManagement/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticDriverManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDriverManagementCreate" */ 'pages/logistic/driverManagement/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticDriverManagementEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDriverManagementEdit" */ 'pages/logistic/driverManagement/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticDriverManagementShow = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDriverManagementShow" */ 'pages/logistic/driverManagement/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

// Pickup Assignment
const LogisticPickupAssignmentCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticPickupAssignmentCreate" */ 'pages/logistic/pickupAssignment/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticPickupAssignmentEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticPickupAssignmentEdit" */ 'pages/logistic/pickupAssignment/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticPickupAssignmentList = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticPickupAssignmentList" */ 'pages/logistic/pickupAssignment/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticPickupAssignmentShow = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticPickupAssignmentShow" */ 'pages/logistic/pickupAssignment/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

// Delivery Assignment
const LogisticDeliveryAssignmentCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDeliveryAssignmentCreate" */ 'pages/logistic/deliveryAssignment/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticDeliveryAssignmentEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDeliveryAssignmentEdit" */ 'pages/logistic/deliveryAssignment/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticDeliveryAssignmentList = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDeliveryAssignmentList" */ 'pages/logistic/deliveryAssignment/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticDeliveryAssignmentShow = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDeliveryAssignmentShow" */ 'pages/logistic/deliveryAssignment/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

// Shuttle Assignment
const LogisticShuttleAssignmentCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticShuttleAssignmentCreate" */ 'pages/logistic/shuttleAssignment/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticShuttleAssignmentEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticShuttleAssignmentEdit" */ 'pages/logistic/shuttleAssignment/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticShuttleAssignmentList = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticShuttleAssignmentList" */ 'pages/logistic/shuttleAssignment/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticShuttleAssignmentShow = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticShuttleAssignmentShow" */ 'pages/logistic/shuttleAssignment/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const Truck = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Truck'));

export const logisticResources = (
  [
    {
      name: 'logistic',
      icon: <Truck size={18} />,
    },
    /* PLOP_INJECT_RESOURCE */
    {
      name: 'logistic_driver-management',
      parentName: 'logistic',
      list: LogisticDriverManagementList,
      create: LogisticDriverManagementCreate,
      edit: LogisticDriverManagementEdit,
      show: LogisticDriverManagementShow,
      canDelete: true,
      options: {
        label: 'Driver Management',
        route: 'driver-management',
      },
    },
    {
      name: 'logistic_pickup-assignment',
      parentName: 'logistic',
      create: LogisticPickupAssignmentCreate,
      edit: LogisticPickupAssignmentEdit,
      list: LogisticPickupAssignmentList,
      show: LogisticPickupAssignmentShow,
      options: {
        label: 'Pickup assignment',
        route: 'pickup-assignment',
      },
    },
    {
      name: 'logistic_delivery-assignment',
      parentName: 'logistic',
      create: LogisticDeliveryAssignmentCreate,
      edit: LogisticDeliveryAssignmentEdit,
      list: LogisticDeliveryAssignmentList,
      show: LogisticDeliveryAssignmentShow,
      options: {
        label: 'Delivery assignment',
        route: 'delivery-assignment',
      },
    },
    {
      name: 'logistic_shuttle-assignment',
      parentName: 'logistic',
      create: LogisticShuttleAssignmentCreate,
      edit: LogisticShuttleAssignmentEdit,
      list: LogisticShuttleAssignmentList,
      show: LogisticShuttleAssignmentShow,
      options: {
        label: 'Shuttle assignment',
        route: 'shuttle-assignment',
      },
    },
  ] as unknown as ResourceProps[]
).filter(envModuleFlagVault);
