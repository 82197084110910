import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_IMPORT */
const HubManagementHubList = loadable(
  () => import(/* webpackChunkName: "HubManagementHubList" */ 'pages/hubManagement/hub/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementHubShow = loadable(
  () => import(/* webpackChunkName: "HubManagementHubShow" */ 'pages/hubManagement/hub/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementHubCreate = loadable(
  () => import(/* webpackChunkName: "HubManagementHubCreate" */ 'pages/hubManagement/hub/create'),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementHubEdit = loadable(
  () => import(/* webpackChunkName: "HubManagementHubEdit" */ 'pages/hubManagement/hub/edit'),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementOutboundManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementOutboundManagementList" */ 'pages/hubManagement/outboundManagement/outboundRequest/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementOutboundManagementShow = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementOutboundManagementShow" */ 'pages/hubManagement/outboundManagement/outboundRequest/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementOutboundManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementOutboundManagementCreate" */ 'pages/hubManagement/outboundManagement/outboundRequest/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementOutboundManagementEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementOutboundManagementEdit" */ 'pages/hubManagement/outboundManagement/outboundRequest/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementOutboundDocumentList = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementOutboundDocumentList" */ 'pages/hubManagement/outboundManagement/outboundDocument/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementOutboundDocumentShow = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementOutboundDocumentShow" */ 'pages/hubManagement/outboundManagement/outboundDocument/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementOutboundDocumentEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementOutboundDocumentEdit" */ 'pages/hubManagement/outboundManagement/outboundDocument/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementInboundManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementInboundManagementList" */ 'pages/hubManagement/inboundManagement/inboundRequest/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementInboundManagementShow = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementInboundManagementShow" */ 'pages/hubManagement/inboundManagement/inboundRequest/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementInboundManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementInboundManagementCreate" */ 'pages/hubManagement/inboundManagement/inboundRequest/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementInboundManagementEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementInboundManagementEdit" */ 'pages/hubManagement/inboundManagement/inboundRequest/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementPickingDocumentList = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementPickingDocumentList" */ 'pages/hubManagement/inboundManagement/pickingDocument/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementPickingDocumentShow = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementPickingDocumentShow" */ 'pages/hubManagement/inboundManagement/pickingDocument/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementPickingDocumentCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementPickingDocumentCreate" */ 'pages/hubManagement/inboundManagement/pickingDocument/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementPickingDocumentEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementPickingDocumentEdit" */ 'pages/hubManagement/inboundManagement/pickingDocument/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementStockAdjustmentList = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementStockAdjustmentList" */ 'pages/hubManagement/stockManagement/stockAdjustment/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementStockAdjustmentShow = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementStockAdjustmentShow" */ 'pages/hubManagement/stockManagement/stockAdjustment/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementStockAdjustmentEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementStockAdjustmentEdit" */ 'pages/hubManagement/stockManagement/stockAdjustment/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementStockDocumentList = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementStockDocumentList" */ 'pages/hubManagement/stockManagement/stockDocument/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementStockDocumentShow = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementStockDocumentShow" */ 'pages/hubManagement/stockManagement/stockDocument/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementPaymentRecapList = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementPaymentRecapList" */ 'pages/hubManagement/paymentRecap/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementPaymentRecapShow = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementPaymentRecapShow" */ 'pages/hubManagement/paymentRecap/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementPaymentRecapCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementPaymentRecapCreate" */ 'pages/hubManagement/paymentRecap/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const Gudang = loadable(() => import('@onefish/icons-react/src/icons/efishery/Gudang'));
const KolamBundar = loadable(() => import('@onefish/icons-react/src/icons/efishery/KolamBundar'));
const Download = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Download'));
const Upload = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Upload'));

export const hubManagementResources: ResourceProps[] = [
  {
    name: 'hub-management',
    icon: <Gudang size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'hub-management_hub',
    parentName: 'hub-management',
    create: HubManagementHubCreate,
    edit: HubManagementHubEdit,
    list: HubManagementHubList,
    show: HubManagementHubShow,
    options: {
      label: 'Hub',
      route: 'hub',
    },
  },
  {
    name: 'hub-management_inbound-management',
    icon: <Download size={18} />,
    parentName: 'hub-management',
    options: {
      label: 'Inbound Management',
      route: 'inbound-management',
    },
  },
  {
    name: 'hub-management_stock-management',
    icon: <KolamBundar size={18} />,
    parentName: 'hub-management',
    options: {
      label: 'Stock Management',
      route: 'stock-management',
    },
  },
  {
    name: 'hub-management_outbound-management',
    icon: <Upload size={18} />,
    parentName: 'hub-management',
    options: {
      label: 'Outbound Management',
      route: 'outbound-management',
    },
  },
  {
    name: 'hub-management_inbound-management_inbound-request',
    parentName: 'hub-management_inbound-management',
    create: HubManagementInboundManagementCreate,
    edit: HubManagementInboundManagementEdit,
    list: HubManagementInboundManagementList,
    show: HubManagementInboundManagementShow,
    options: {
      label: 'Inbound request',
      route: 'inbound-request',
    },
  },
  {
    name: 'hub-management_inbound-management_picking-document',
    parentName: 'hub-management_inbound-management',
    create: HubManagementPickingDocumentCreate,
    edit: HubManagementPickingDocumentEdit,
    list: HubManagementPickingDocumentList,
    show: HubManagementPickingDocumentShow,
    options: {
      label: 'Picking document',
      route: 'picking-document',
    },
  },
  {
    name: 'hub-management_stock-management_stock-adjustment',
    parentName: 'hub-management_stock-management',
    edit: HubManagementStockAdjustmentEdit,
    list: HubManagementStockAdjustmentList,
    show: HubManagementStockAdjustmentShow,
    options: {
      label: 'Stock Adjustment',
      route: 'stock-adjustment',
    },
  },
  {
    name: 'hub-management_stock-management_stock-document',
    parentName: 'hub-management_stock-management',
    list: HubManagementStockDocumentList,
    show: HubManagementStockDocumentShow,
    options: {
      label: 'Stock Document',
      route: 'stock-document',
    },
  },
  {
    name: 'hub-management_outbound-management_outbound-request',
    parentName: 'hub-management_outbound-management',
    create: HubManagementOutboundManagementCreate,
    list: HubManagementOutboundManagementList,
    show: HubManagementOutboundManagementShow,
    edit: HubManagementOutboundManagementEdit,
    options: {
      label: 'Outbound request',
      route: 'outbound-request',
    },
  },
  {
    name: 'hub-management_outbound-management_outbound-document',
    parentName: 'hub-management_outbound-management',
    list: HubManagementOutboundDocumentList,
    show: HubManagementOutboundDocumentShow,
    edit: HubManagementOutboundDocumentEdit,
    options: {
      label: 'Outbound Document',
      route: 'outbound-document',
    },
  },
  {
    name: 'hub-management_payment-recap',
    parentName: 'hub-management',
    create: HubManagementPaymentRecapCreate,
    list: HubManagementPaymentRecapList,
    show: HubManagementPaymentRecapShow,
    options: {
      label: 'Recap Payment',
      route: 'payment-recap',
    },
  },
].filter(envModuleFlagVault);
