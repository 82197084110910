import { useCallback, useEffect, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { HttpError, useLogin, useNavigation } from '@pankod/refine-core';
import { Row, Spin } from '@pankod/refine-antd';

export default function Callback() {
  const ignore = useRef(false);

  const { mutateAsync: login } = useLogin();
  const { push } = useNavigation();

  const queryParams = new URLSearchParams(window.location.search);
  const clientId = queryParams.get('client_id');
  const code = queryParams.get('code');
  const grantType = queryParams.get('grant_type');
  const error = queryParams.get('error_message');

  const handleAuthentication = useCallback(async () => {
    if (error) {
      push(`/login?error=${error}`);
      return;
    }

    try {
      await login({
        clientId,
        code,
        grantType,
      });
    } catch (error) {
      const axiosError = error as AxiosError<HttpError>;
      push(`/login?error=${axiosError.response?.data.message || 'Failed to login'}`);
    }
  }, [clientId, code, error, grantType, login, push]);

  useEffect(() => {
    if (!ignore.current) {
      handleAuthentication();
    }

    return () => {
      ignore.current = true;
    };
  }, [handleAuthentication]);

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Spin size="large" indicator={<LoadingOutlined spin />} />
    </Row>
  );
}
