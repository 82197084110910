import React, { ComponentProps, useEffect, useState } from 'react';
import { LogoutOutlined, UnorderedListOutlined, DashboardOutlined } from '@ant-design/icons';
import { AntdLayout, Menu, Grid, Title as DefaultTitle, Typography } from '@pankod/refine-antd';
import {
  useTranslate,
  useLogout,
  useTitle,
  ITreeMenu,
  useIsExistAuthentication,
  useCanWithoutCache,
  useMenu,
  useNavigation,
  useRefineContext,
} from '@pankod/refine-core';
import { useUserIdentity } from '@hooks/useUserIdentity';

import { localeOptions } from '@constants/i18n';
import { i18nActivate } from '@libs/i18n';

import { antLayoutSider, antLayoutSiderMobile } from './styles';

type ItemType = NonNullable<ComponentProps<typeof Menu>['items']>[number];

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isExistAuthentication = useIsExistAuthentication();
  const { mutate: logout } = useLogout();
  const Title = useTitle();
  const translate = useTranslate();
  const { push } = useNavigation();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { userCountry } = useUserIdentity();
  const breakpoint = Grid.useBreakpoint();
  const { can: checkCanAccess } = useCanWithoutCache();
  const { hasDashboard } = useRefineContext();
  const [renderedMenuItems, setRenderedMenuItems] = React.useState<Array<ItemType>>([]);

  const isMobile = !breakpoint.lg;
  const width = isMobile ? 0 : collapsed ? 80 : 248;

  const RenderToTitle = Title ?? DefaultTitle;

  const handleTreeMenuItem = React.useCallback(
    async (item: ITreeMenu, selectedKey?: string): Promise<ItemType | undefined> => {
      if (item.children?.length > 0) {
        const handledChildren = await Promise.all(
          item.children.map((child) => handleTreeMenuItem(child, selectedKey)),
        );

        // is sub
        if (handledChildren.filter(Boolean).length > 0) {
          return {
            key: item.route,
            label: item.label,
            icon: item.icon ?? <UnorderedListOutlined />,
            title: item.label,
            children: handledChildren,
          } as ItemType;
        }

        return undefined;
      } else {
        const isSelected = item.route === selectedKey;
        const isRoute = !(item.parentName !== undefined && item.children.length === 0);

        const { can: hasAccess } = checkCanAccess
          ? await checkCanAccess({
              resource: item.name,
              action: 'list',
            })
          : { can: true };
        // is leaf
        if (hasAccess) {
          return {
            key: item.route,
            label: (
              <Typography.Text
                ellipsis={{ tooltip: item.label }}
                style={{ color: 'inherit', textTransform: 'capitalize' }}
              >
                {item.label}
                {!collapsed && isSelected && <div className="ant-menu-tree-arrow" />}
              </Typography.Text>
            ),
            icon: item.icon ?? (isRoute && <UnorderedListOutlined />),
            style: isSelected ? { fontWeight: 600 } : { fontWeight: 'normal' },
          } as ItemType;
        }
        return undefined;
      }
    },
    [collapsed, checkCanAccess],
  );

  const renderTree = React.useCallback(async (items: ITreeMenu[], selected: string) => {
    const promises = items.map((item) => handleTreeMenuItem(item, selected));
    setRenderedMenuItems((await Promise.all(promises)).filter(Boolean) as ItemType[]);
  }, []);

  useEffect(() => {
    renderTree(menuItems, selectedKey);
  }, [menuItems, selectedKey]);

  useEffect(() => {
    const locale = localeOptions[userCountry].language;
    i18nActivate(locale);
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          width: width,
          overflow: 'hidden',
          flex: `0 0 ${width}`,
          maxWidth: width,
          minWidth: width,
          transition:
            'background-color 0.3s ease 0s, min-width 0.3s ease 0s, max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
        }}
      ></div>
      <AntdLayout.Sider
        width={248}
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
        collapsedWidth={isMobile ? 0 : 80}
        breakpoint="lg"
        style={isMobile ? antLayoutSiderMobile : antLayoutSider}
      >
        <div style={{ overflowY: 'auto', height: '100vh' }}>
          <RenderToTitle collapsed={collapsed} />

          <Menu
            selectedKeys={[selectedKey]}
            defaultSelectedKeys={defaultOpenKeys}
            defaultOpenKeys={defaultOpenKeys}
            mode="inline"
            style={{ paddingBottom: isMobile ? '0' : '48px' }}
            onClick={({ key }) => {
              if (!breakpoint.lg) {
                setCollapsed(true);
              }

              push(key as string);
            }}
            items={[
              ...(hasDashboard
                ? [
                    {
                      key: '/',
                      label: translate('dashboard.title', 'Dashboard'),
                      icon: <DashboardOutlined />,
                      style: selectedKey === '/' ? { fontWeight: 600 } : { fontWeight: 'normal' },
                    } as ItemType,
                  ]
                : []),
              ...renderedMenuItems,
              ...(isExistAuthentication
                ? [
                    {
                      key: 'logout',
                      label: translate('buttons.logout', 'Logout'),
                      onClick: () => logout(),
                      icon: <LogoutOutlined />,
                    } as ItemType,
                  ]
                : []),
            ]}
          />
        </div>
      </AntdLayout.Sider>
    </React.Fragment>
  );
};
