import { ResourceProps } from '@pankod/refine-core';

// after generated, please sort it alphabetically by its filename
/* PLOP_INJECT_RESOURCE_IMPORT */
import { b2bResources } from './b2b';
import { buyerResources } from './buyer';
import { campaignResources } from './campaign';
import { coreResources } from './core';
import { digitalSignResources } from './digitalSign';
import { enumerationResources } from './enumeration';
import { farmerResources } from './farmer';
import { hubManagementResources } from './hubManagement';
import { logisticResources } from './logistics';
import { merchandiserResources } from './merchandiser';
import { pricingResources } from './pricing';
import { qualityControlResources } from './qualityControl';
import { regionResources } from './region';
import { salesResources } from './sales';
import { settingResources } from './setting';
import { supplyResources } from './supply';
import { systemResources } from './system';
import { userResources } from './user';
import { warehouseResources } from './warehouse';

export const resources: ResourceProps[] = [
  // after generated, please sort it alphabetically by its resource's name
  /* PLOP_INJECT_RESOURCE_ARRAY */
  ...b2bResources,
  ...buyerResources,
  ...campaignResources,
  ...coreResources,
  ...digitalSignResources,
  ...enumerationResources,
  ...farmerResources,
  ...hubManagementResources,
  ...logisticResources,
  ...merchandiserResources,
  ...pricingResources,
  ...qualityControlResources,
  ...regionResources,
  ...salesResources,
  ...settingResources,
  ...supplyResources,
  ...systemResources,
  ...userResources,
  ...warehouseResources,
];
