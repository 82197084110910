import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_LOADABLE */
const QualityControlStaffCreate = loadable(
  () =>
    import(/* webpackChunkName: "QualityControlStaffCreate" */ 'pages/qualityControl/staff/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlStaffEdit = loadable(
  () => import(/* webpackChunkName: "QualityControlStaffEdit" */ 'pages/qualityControl/staff/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlStaffList = loadable(
  () => import(/* webpackChunkName: "QualityControlStaffList" */ 'pages/qualityControl/staff/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlStaffShow = loadable(
  () => import(/* webpackChunkName: "QualityControlStaffShow" */ 'pages/qualityControl/staff/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlFirstMilesList = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlFirstMilesList" */ 'pages/qualityControl/firstMiles/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlFirstMilesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlFirstMilesShow" */ 'pages/qualityControl/firstMiles/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlLastMilesList = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlLastMilesList" */ 'pages/qualityControl/lastMiles/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlLastMilesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlLastMilesShow" */ 'pages/qualityControl/lastMiles/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlOrderExternalReferencesList = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlOrderExternalReferencesList" */ 'pages/qualityControl/orderExternalReferences/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlOrderExternalReferencesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlOrderExternalReferencesShow" */ 'pages/qualityControl/orderExternalReferences/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const ShieldCheck = loadable(() => import('@onefish/icons-react/src/icons/phosphor/ShieldCheck'));

export const qualityControlResources: ResourceProps[] = [
  {
    name: 'quality-control',
    icon: <ShieldCheck size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'quality-control_staff',
    parentName: 'quality-control',
    create: QualityControlStaffCreate,
    edit: QualityControlStaffEdit,
    list: QualityControlStaffList,
    show: QualityControlStaffShow,
    options: {
      label: 'Staff Coverage',
      route: 'staff',
    },
  },
  {
    name: 'quality-control_first-miles',
    parentName: 'quality-control',
    list: QualityControlFirstMilesList,
    show: QualityControlFirstMilesShow,
    options: {
      label: 'First mile',
      route: 'first-miles',
    },
  },
  {
    name: 'quality-control_last-miles',
    parentName: 'quality-control',
    list: QualityControlLastMilesList,
    show: QualityControlLastMilesShow,
    options: {
      label: 'Last mile',
      route: 'last-miles',
    },
  },
  {
    name: 'quality-control_order-external-references',
    parentName: 'quality-control',
    list: QualityControlOrderExternalReferencesList,
    show: QualityControlOrderExternalReferencesShow,
    options: {
      label: 'Order external references',
      route: 'order-external-references',
    },
  },
].filter(envModuleFlagVault);
