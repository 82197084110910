import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';
import { LoadingScreen } from 'components';

/* PLOP_INJECT_LOADABLE */
const BuyerLeadsCreate = loadable(
  () => import(/* webpackChunkName: "BuyerLeadsCreate" */ 'pages/buyer/leads/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadsEdit = loadable(
  () => import(/* webpackChunkName: "BuyerLeadsEdit" */ 'pages/buyer/leads/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadsList = loadable(
  () => import(/* webpackChunkName: "BuyerLeadsList" */ 'pages/buyer/leads/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadsShow = loadable(
  () => import(/* webpackChunkName: "BuyerLeadsShow" */ 'pages/buyer/leads/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadOutletsCreate = loadable(
  () => import(/* webpackChunkName: "BuyerLeadOutletsCreate" */ 'pages/buyer/leadOutlets/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadOutletsEdit = loadable(
  () => import(/* webpackChunkName: "BuyerLeadOutletsEdit" */ 'pages/buyer/leadOutlets/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadOutletsList = loadable(
  () => import(/* webpackChunkName: "BuyerLeadOutletsList" */ 'pages/buyer/leadOutlets/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadOutletsShow = loadable(
  () => import(/* webpackChunkName: "BuyerLeadOutletsShow" */ 'pages/buyer/leadOutlets/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadCommoditiesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "BuyerLeadCommoditiesCreate" */ 'pages/buyer/leadCommodities/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadCommoditiesEdit = loadable(
  () =>
    import(/* webpackChunkName: "BuyerLeadCommoditiesEdit" */ 'pages/buyer/leadCommodities/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadCommoditiesList = loadable(
  () =>
    import(/* webpackChunkName: "BuyerLeadCommoditiesList" */ 'pages/buyer/leadCommodities/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const BuyerLeadCommoditiesShow = loadable(
  () =>
    import(/* webpackChunkName: "BuyerLeadCommoditiesShow" */ 'pages/buyer/leadCommodities/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const Petugas = loadable(() => import('@onefish/icons-react/src/icons/efishery/Petugas'));

export const buyerResources: ResourceProps[] = [
  {
    name: 'buyer',
    icon: <Petugas size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'buyer_leads',
    parentName: 'buyer',
    create: BuyerLeadsCreate,
    edit: BuyerLeadsEdit,
    list: BuyerLeadsList,
    show: BuyerLeadsShow,
    canDelete: true,
    options: {
      label: 'leads',
      route: 'leads',
    },
  },
  {
    name: 'buyer_lead-outlets',
    parentName: 'buyer',
    create: BuyerLeadOutletsCreate,
    edit: BuyerLeadOutletsEdit,
    list: BuyerLeadOutletsList,
    show: BuyerLeadOutletsShow,
    canDelete: true,
    options: {
      label: 'lead outlets',
      route: 'lead-outlets',
    },
  },
  {
    name: 'buyer_lead-commodities',
    parentName: 'buyer',
    create: BuyerLeadCommoditiesCreate,
    edit: BuyerLeadCommoditiesEdit,
    list: BuyerLeadCommoditiesList,
    show: BuyerLeadCommoditiesShow,
    options: {
      label: 'Lead commodities',
      route: 'lead-commodities',
    },
  },
].filter(envModuleFlagVault);
