import authRoutes from './auth';
import b2bRoutes from './b2b';
import historyRoutes from './history';
import reviewRoutes from './review';
import attendanceRoutes from './attendance';

export default [
  ...authRoutes,
  ...historyRoutes,
  ...reviewRoutes,
  ...b2bRoutes,
  ...attendanceRoutes,
];
