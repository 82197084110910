import { CountriesType, localeOptions } from '@constants/i18n';
import { isEmptyOrSpaces } from './stringChecker';
import { IFormattedNumberToNumberParams, INumberFormatterParams } from './types';
import { UserProfile } from '@efishery/sdk-core';

export const thousandsSeparator = (number: number | string) => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0';
};

export const currencyFormat = (val: number) => {
  return val === 0
    ? 'Rp -'
    : new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
      }).format(val);
};

export const currencyFormatInter = (value: any, userCountry: CountriesType) => {
  if (value === '') return '';

  return new Intl.NumberFormat(localeOptions[userCountry].numberFormat, {
    currency: localeOptions[userCountry].currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(value));
};

export const numberInputFormatter = (value: any) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const numberInputParser = (value: any) => (value ? value.replace(/\$\s?|(\.*)/g, '') : '');

export const decimalNumberInputFormatter = (value: any) =>
  `${value}`.replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const decimalNumberInputParser = (value: any) =>
  value ? value.replace(/\s|(\.*)/g, '').replace(/,/g, '.') : '';

export const numbInputPercentFormatter = (value: any) => (value ? `${value} %` : '');
export const numbInputPercentParser = (value: any) => {
  let result = (value || '').replace(/[^0-9\.]/g, '');
  if (result.split('.').length > 2) result = result.replace(/\.+$/, '');
  return result;
};

export const censoredBankHandler = (type: 'ACCOUNT_NUMBER' | 'ACCOUNT_NAME', data: any): string => {
  if (type === 'ACCOUNT_NUMBER') {
    return data.replace(
      data.slice(2, data.length - 2),
      '*'.repeat(data.slice(2, data.length - 2).length),
    );
  }
  const splitText = data.split(' ');
  return splitText
    .map((item: string) =>
      item.replace(
        item.slice(1, item.length - 1),
        '*'.repeat(item.slice(1, item.length - 1).length),
      ),
    )
    .join(' ');
};

export const transactionNumberFormatter = (val: number, type: string) => {
  switch (type) {
    case 'rupiah':
      return currencyFormat(val);
    default:
      return numberInputFormatter(val);
  }
};

export const titleCase = (words: string) => {
  if (words === undefined || typeof words !== 'string') {
    return '';
  }

  return words.replace(/(\w)(\w*)/g, function (_g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const emptyTextHandler = (value?: string | number | null): string => {
  const text = value?.toString();
  if (text && !isEmptyOrSpaces(text)) return text;
  return '-';
};

export const stringToRegex = (str: string): RegExp => {
  const escapedStr = str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regexStr = escapedStr.replace(/\*/g, '.*');

  const pattern = `^${regexStr}`;
  return new RegExp(pattern);
};

export const currencyRegex = /\B(?=(\d{3})+(?!\d))/g;

export const numberFormatter = ({
  number,
  separator,
  maximumFractionDigits,
  prefix = '',
  suffix = '',
  fractionSeparator = ',',
  withPrefixIfEmpty = false,
  withSuffixIfEmpty = false,
}: INumberFormatterParams) => {
  if (number === 0) {
    let returnValue = '';

    if (withPrefixIfEmpty || withSuffixIfEmpty) {
      returnValue = '0';
    }

    if (withPrefixIfEmpty) {
      returnValue = prefix + returnValue;
    }

    if (withSuffixIfEmpty) {
      returnValue = returnValue + suffix;
    }

    return returnValue;
  }
  const splittedFractionNumber = new Intl.NumberFormat('id-ID', {
    currency: 'IDR',
    maximumFractionDigits,
  })
    .format(number)
    .split(',');

  const numberWithoutFraction = splittedFractionNumber[0] || '';

  let formattedNumber = numberWithoutFraction.split('.').join(separator);
  if (splittedFractionNumber.length === 2) {
    formattedNumber =
      formattedNumber +
        fractionSeparator +
        splittedFractionNumber[splittedFractionNumber.length - 1] || '';
  }

  return prefix + formattedNumber + suffix;
};

export const formattedNumberToNumber = ({
  numberStr,
  separator,
  fractionSeparator,
  prefix = '',
  suffix = '',
}: IFormattedNumberToNumberParams) => {
  let almostNumber = numberStr.replace(prefix, '').replace(suffix, '');

  if (fractionSeparator === undefined) {
    almostNumber = almostNumber.split(separator).join('');
    return Number(almostNumber);
  }

  const splittedNumber = almostNumber.split(separator).join('').split(fractionSeparator);
  const fractionNumber = splittedNumber.pop();

  if (fractionNumber !== undefined) {
    almostNumber = fractionNumber;

    if (splittedNumber.length !== 0) {
      almostNumber = splittedNumber.join('') + '.' + fractionNumber;
    }
  }
  return Number(almostNumber);
};

export const rupiahFormatter = (value?: string) => {
  return typeof value === 'string' && value !== ''
    ? numberFormatter({
        number: Number(value),
        separator: '.',
        prefix: 'Rp ',
        fractionSeparator: '.',
        withPrefixIfEmpty: true,
      })
    : '';
};

export const rupiahParser = (numberStr?: string) => {
  return typeof numberStr === 'string' && numberStr !== ''
    ? String(
        formattedNumberToNumber({
          numberStr,
          separator: '.',
          prefix: 'Rp ',
          fractionSeparator: '.',
        }),
      )
    : '';
};

export const kilogramFormatter = (value?: string) => {
  return typeof value === 'string' && value !== ''
    ? numberFormatter({ number: Number(value), separator: ',', suffix: ' kg' })
    : '';
};

export const kilogramParser = (value?: string) => {
  return typeof value === 'string' && value !== ''
    ? String(value).replace(/\ kg\s?|(,*)/g, '')
    : '';
};

export const permissionsToString = (permissions: UserProfile['permissions']) => {
  if (!permissions) return '';

  const policy = permissions.reduce((result: string, subject: string[]) => {
    const str = subject.join(', ');
    result += `${str}\n`;
    return result;
  }, ``);

  return policy;
};
