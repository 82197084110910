import { AxiosInstance } from 'axios';
import { DataProvider, CrudOperators, getDefaultFilter, LogicalFilter } from '@pankod/refine-core';
import { convertResourceToEndpoint, dataProvider } from './dataProvider';
import { axiosInstance } from 'libs/utils';
import { isLogicalCrudFilter } from 'libs/helpers';

type SortType = 'ASC' | 'DESC';

interface IOrderBy {
  field_name: string;
  sort: SortType;
}

interface IQuery {
  limit?: number;
  offset?: number;
  where?: object;
  order_by?: IOrderBy[];
}

const mapOperatorV2 = (operator: CrudOperators): string => {
  switch (operator) {
    case 'contains':
      return 'isubstring';
    default:
      return operator;
  }
};

const defaultSort: IOrderBy = {
  field_name: 'created_at',
  sort: 'DESC',
};

const promoProvider = (apiUrl: string, httpClient: AxiosInstance = axiosInstance): DataProvider => {
  const defaultDataProvider = dataProvider(apiUrl, httpClient);

  return {
    ...defaultDataProvider,
    getList: async ({ resource, pagination, filters, sort, metaData }) => {
      const endpoint = metaData?.url ?? convertResourceToEndpoint(resource);
      const url = `${apiUrl}/${endpoint}`;

      const query: IQuery = {
        limit: pagination?.pageSize ?? 10,
        offset: ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10),
      };

      const blacklist: string = getDefaultFilter(metaData?.blacklist, filters);

      const filteredFilters = (filters ?? []).filter((filter) => {
        if (
          metaData?.blacklist &&
          isLogicalCrudFilter(filter) &&
          filter.field === metaData?.blacklist
        ) {
          return !(blacklist === 'false');
        }
        return true;
      });

      if (filteredFilters && filteredFilters?.length > 0) {
        const w_and: any = {};
        for (const filter of filteredFilters) {
          const f = filter as LogicalFilter;
          w_and[f.field] = {
            [mapOperatorV2(f.operator)]: f.value,
          };
        }
        if (Object.keys(w_and).length > 0) {
          query.where = { and: w_and };
        }
      }

      if (sort && sort.length > 0) {
        query.order_by = sort.map((s) => ({
          field_name: s.field,
          sort: s.order.toUpperCase() as SortType,
        }));
      } else {
        query.order_by = [defaultSort];
      }

      const res = await httpClient.get(`${url}?type=offset&query=${JSON.stringify(query)}`, {
        headers: metaData?.headers,
      });

      const data = res?.data?.data;
      const total = res?.data?.pagination?.total_datas ?? data?.length;

      return {
        data,
        total,
      };
    },
  };
};

export { promoProvider };
