import { localeOptions } from '@constants/i18n';
import { IUser } from '@interfaces/user.model';
import { CrudFilters, useGetIdentity } from '@pankod/refine-core';

export const useUserIdentity = () => {
  const { data: user } = useGetIdentity<IUser>();
  let userCountry = localeOptions.indonesia.label.toLowerCase();

  const isIndiaUser = user?.roles?.some((roleSet) =>
    roleSet.some((role) => /^octo.*india$/.test(role)),
  );

  if (isIndiaUser) userCountry = localeOptions.india.label.toLowerCase();

  const countryId = localeOptions[userCountry].code;

  const filterByCountry: CrudFilters = [
    {
      field: 'country_id',
      operator: 'eq',
      value: countryId,
    },
  ];

  const addCountryIdToValues = (values: any) => {
    return {
      ...values,
      country_id: countryId,
    };
  };

  const dataProviderSupportService = isIndiaUser ? 'support_service_inter' : 'support_service';
  const dataProviderCustomerService = isIndiaUser ? 'customer_service_inter' : 'customer_service';

  const interLocationResources = {
    provinces: {
      resource: 'region_provinces',
      optionLabel: 'name',
      optionValue: 'id',
    },
    regencies: {
      resource: 'region_regencies',
      optionLabel: 'name',
      optionValue: 'id',
    },
    districts: {
      resource: 'region_districts',
      optionLabel: 'name',
      optionValue: 'id',
    },
    dataProviderName: 'default',
  };

  const otherLocationResources = {
    provinces: {
      resource: 'locations_province',
      optionLabel: 'province_name',
      optionValue: 'province_id',
    },
    regencies: {
      resource: 'locations_city',
      optionLabel: 'city_name',
      optionValue: 'city_id',
    },
    districts: {
      resource: 'locations_district',
      optionLabel: 'district_name',
      optionValue: 'district_id',
    },
    dataProviderName: 'support_service',
  };

  const locationResource = isIndiaUser ? interLocationResources : otherLocationResources;

  return {
    isIndiaUser,
    userCountry,
    countryId,
    filterByCountry,
    addCountryIdToValues,
    dataProviderSupportService,
    dataProviderCustomerService,
    locationResource,
  };
};
