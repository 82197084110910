export const ENV = {
  APP_ID: process.env.REACT_APP_ID || 'octo',
  PMS_ID: process.env.REACT_APP_PMS_ID || 'fish-in-tools',
  APP_VERSION: (process.env.REACT_APP_VERSION || 'local-v0.0.0-pre-alpha').replace(
    /\b(?:\local-v|stg-v|uat-v|v)/gi,
    '',
  ),
  APP_COMMIT: process.env.REACT_APP_COMMIT || '',
  APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || 'local',
  KEYSTORAGE_TOKEN: process.env.REACT_APP_KEYSTORAGE_TOKEN || 'fit__tkn',
  KEYSTORAGE_REFRESH_TOKEN: process.env.REACT_APP_KEYSTORAGE_REFRESH_TOKEN || 'fit__rfsh_tkn',
  KEYSTORAGE_USER: process.env.REACT_APP_KEYSTORAGE_USER || 'fit__user',
  EFRESH_URL: process.env.REACT_APP_EFRESH_URL || 'https://stg-fresh.efishery.com',
  EFRESH_PUBLIC_SUPPLY_PATH: process.env.REACT_APP_EFRESH_PUBLIC_SUPPLY_PATH || 'supplies',
  EFRESH_PRIVATE_SUPPLY_PATH: process.env.REACT_APP_EFRESH_PRIVATE_SUPPLY_PATH || 'supplyku',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  AUTH_CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '',
  APP_STAGE: process.env.REACT_APP_STAGE || 'stg',
  CRM_APP_TOKEN: process.env.REACT_APP_CRM_APP_TOKEN || '',
  PAYMENT_EXPIRED_MINUTES: process.env.REACT_APP_PAYMENT_EXPIRED_MINUTES || 24 * 60,
  PAYMENT_VA_EXPIRED_MINUTES: process.env.REACT_APP_PAYMENT_VA_EXPIRED_MINUTES || 24 * 60,
  FLAGRID_MAINTENANCE: parseInt(process.env.REACT_APP_FLAGRID_MAINTENANCE_FEATURE || '0', 10),
  MAX_SELECTED_SYNC_STOCK_DOCUMENT: parseInt(
    process.env.REACT_APP_MAX_SELECTED_SYNC_STOCK_DOCUMENT || '5',
    10,
  ),
  CATFISH_GRAPHQL_URL:
    process.env.REACT_APP_SERVICE_CATFISH_GRAPHQL ||
    'https://stg-catfish.service.efishery.com/graphql',
  SAWARNA_GRAPHQL_URL:
    process.env.REACT_APP_SERVICE_SAWARNA_GRAPHQL ||
    'https://stg-sawarna-v2.service.efishery.com/graphql',
  REGION_CODE_OPTIONS: process.env.REACT_APP_REGION_CODE_OPTIONS
    ? process.env.REACT_APP_REGION_CODE_OPTIONS?.toString()
        ?.split('_')
        ?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
    : [],
  ENABLE_COOKIE_KEYS: process.env.REACT_APP_ENABLE_COOKIE_KEYS === '1' ? true : false,
  ENABLE_ACCESS_CONTROL_LOCAL_STRATEGY: ['1', 'true'].includes(
    String(process.env.REACT_APP_ENABLE_ACCESS_CONTROL_LOCAL_STRATEGY),
  ),
};
