import { CrudFilter, LogicalFilter } from '@pankod/refine-core';

// type guards
export const isLogicalCrudFilter = (filter: CrudFilter): filter is LogicalFilter => {
  return (filter as LogicalFilter).field !== undefined;
};
import type { IUser, IUserV1 } from 'interfaces';

export function isUserV2(user: IUser | IUserV1): user is IUser {
  return (user as IUser).roles !== undefined;
}
