import { AntdLayout, Space, Typography } from '@pankod/refine-antd';
import { ENV } from 'config/env';
import React from 'react';

export const Footer: React.FC = () => (
  <AntdLayout.Footer
    style={{
      padding: '12px 24px',
      marginTop: 'auto',
    }}
  >
    <Space size="large">
      <Typography.Text type="secondary" style={{ fontWeight: 500 }}>
        <span style={{ textTransform: 'capitalize' }}>{ENV.APP_ID.toLowerCase()}</span>{' '}
        {ENV.APP_VERSION}
      </Typography.Text>

      <Typography.Text type="secondary" style={{ fontWeight: 600 }}>
        {ENV.APP_COMMIT.substring(0, 7)}
      </Typography.Text>
    </Space>
  </AntdLayout.Footer>
);
