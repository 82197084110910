import loadable from '@loadable/component';
import { RefineRouteProps } from '@pankod/refine-react-router-v6';
import { LoadingScreen } from 'components';

const B2bCustomerAgencyRight = loadable(
  () => import(/* webpackChunkName: "B2bCustomerAgencyRight" */ 'pages/b2b/customer/agency'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bCustomerFleets = loadable(
  () => import(/* webpackChunkName: "B2bCustomerFleets" */ 'pages/b2b/customer/fleets'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bCustomerSales = loadable(
  () => import(/* webpackChunkName: "B2bCustomerSales" */ 'pages/b2b/customer/sales'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bCustomerWarehouse = loadable(
  () => import(/* webpackChunkName: "B2bCustomerWarehouse" */ 'pages/b2b/customer/warehouse'),
  {
    fallback: <LoadingScreen />,
  },
);

const b2bRoutes: RefineRouteProps[] = [
  {
    element: <B2bCustomerWarehouse />,
    path: '/b2b/customers/:id/warehouse',
    layout: true,
  },
  {
    element: <B2bCustomerAgencyRight />,
    path: '/b2b/customers/:id/agency-right',
    layout: true,
  },
  {
    element: <B2bCustomerFleets />,
    path: '/b2b/customers/:id/fleet',
    layout: true,
  },
  {
    element: <B2bCustomerSales />,
    path: '/b2b/customers/:id/sales',
    layout: true,
  },
];

export default b2bRoutes;
