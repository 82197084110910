export const isEmptyOrSpaces = (str?: string | null) => {
  return (
    str === null || str === undefined || (typeof str === 'string' && str.match(/^ *$/) !== null)
  );
};

export const isCorrectPhoneNumber = (str?: string, countryId = 62): boolean => {
  return (
    str === null ||
    str === undefined ||
    (typeof str === 'string' && str.slice(0, 2) === countryId?.toString())
  );
};
