import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { HttpError } from '@pankod/refine-core';
import { ENV } from 'config/env';
import { SERVICE } from 'config/service';
import { Auth, Stage } from '@efishery/sdk-core';
import { captureError } from './captureError';

const auth = new Auth(ENV.AUTH_CLIENT_ID, Stage[ENV.APP_ENVIRONMENT as keyof typeof Stage]);

const addHeaderV2Interceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config.headers !== undefined) {
        const { headers } = config;
        const extraHeaders = {
          'X-Client-Id': ENV.APP_ID,
          'X-Client-Version': ENV.APP_VERSION,
          Authorization: `Bearer ${await auth.getAccessToken()}`,
        };

        config.headers = {
          ...extraHeaders,
          ...headers,
        };
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

const addRefreshTokenV2Interceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err?.config;

      if (!originalConfig?.sent && err?.response?.status === 401) {
        originalConfig.sent = true;

        try {
          const { accessToken } = await auth.persistRefresh();
          originalConfig['headers'] = {
            Authorization: `Bearer ${accessToken}`,
          };

          if (!originalConfig.url?.includes(SERVICE.PROMO)) {
            Object.keys(originalConfig.headers)
              .filter((key) => key.toLowerCase().includes('x-app'))
              .forEach((headerKey) =>
                Object.assign(originalConfig.headers, { [headerKey]: accessToken }),
              );
          }

          return await axiosInstance(originalConfig);
        } catch (err) {
          await auth.logout();
          return Promise.reject(err);
        }
      }

      const customError: HttpError = {
        ...err,
        message: err.response?.data?.metadata?.error?.message || err.response?.data?.message,
        statusCode: err.response?.data?.metadata?.error?.code || err?.response?.status,
      };

      captureError(err);

      return Promise.reject(customError);
    },
  );
};

const createAxios = () => {
  const axiosInstance = axios.create();

  addHeaderV2Interceptor(axiosInstance);
  addRefreshTokenV2Interceptor(axiosInstance);

  return axiosInstance;
};

const createAxiosPMS = () => {
  const axiosInstance = axios.create();
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config.headers !== undefined) {
        const { headers } = config;
        const extraHeaders = {
          'X-Client-Id': 'fish-in-tools',
          'X-Client-Version': ENV.APP_VERSION,
          Authorization: `Bearer ${await auth.getAccessToken()}`,
        };

        config.headers = {
          ...extraHeaders,
          ...headers,
        };
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  addRefreshTokenV2Interceptor(axiosInstance);
  return axiosInstance;
};

export const axiosInstance = createAxios();
export const axiosInstancePMS = createAxiosPMS();
