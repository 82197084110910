import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';
import { LoadingScreen } from 'components';

/* PLOP_INJECT_LOADABLE */
const SupplyFarmerList = loadable(
  () => import(/* webpackChunkName: "SupplyFarmerList" */ 'pages/supply/farmers/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyFarmerShow = loadable(
  () => import(/* webpackChunkName: "SupplyFarmerShow" */ 'pages/supply/farmers/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyFlbList = loadable(
  () => import(/* webpackChunkName: "SupplyFlbList" */ 'pages/supply/flb/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyFlbShow = loadable(
  () => import(/* webpackChunkName: "SupplyFlbShow" */ 'pages/supply/flb/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyStockCardsList = loadable(
  () => import(/* webpackChunkName: "SupplyStockCardsList" */ 'pages/supply/stockCards/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyStockCardsShow = loadable(
  () => import(/* webpackChunkName: "SupplyStockCardsShow" */ 'pages/supply/stockCards/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyStockCardsListFlb = loadable(
  () => import(/* webpackChunkName: "SupplyStockCardsListFlb" */ 'pages/supply/stockCards/listFlb'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyStockCardsShowFlb = loadable(
  () => import(/* webpackChunkName: "SupplyStockCardsShowFlb" */ 'pages/supply/stockCards/showFlb'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyStockCardsListDownstream = loadable(
  () =>
    import(
      /* webpackChunkName: "SupplyStockCardsListDownstream" */ 'pages/supply/stockCards/listDownstream'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyStockCardsShowDownstream = loadable(
  () =>
    import(
      /* webpackChunkName: "SupplyStockCardsShowDownstream" */ 'pages/supply/stockCards/showDownstream'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyRmpList = loadable(
  () => import(/* webpackChunkName: "SupplyRmpList" */ 'pages/supply/rmp/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SupplyRmpShow = loadable(
  () => import(/* webpackChunkName: "SupplyRmpShow" */ 'pages/supply/rmp/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const ARAPSettlementList = loadable(
  () => import(/* webpackChunkName: "ARAPSettlementList" */ 'pages/supply/arApSettlement/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const ARAPSettlementShow = loadable(
  () => import(/* webpackChunkName: "ARAPSettlementShow" */ 'pages/supply/arApSettlement/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const Ikan = loadable(() => import('@onefish/icons-react/src/icons/efishery/Ikan'));

export const supplyResources: ResourceProps[] = [
  {
    name: 'supply',
    icon: <Ikan size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'supply_suppliers',
    parentName: 'supply',
    list: SupplyFarmerList,
    show: SupplyFarmerShow,
    options: {
      label: 'Farmers',
      route: 'farmers',
    },
  },
  {
    name: 'supply_flb',
    parentName: 'supply',
    list: SupplyFlbList,
    show: SupplyFlbShow,
    options: {
      label: 'First Level Buyers',
      route: 'flbs',
    },
  },
  {
    name: 'supply_stock-cards',
    parentName: 'supply',
    list: SupplyStockCardsList,
    show: SupplyStockCardsShow,
    options: {
      label: 'Stock Cards',
      route: 'stock-cards',
    },
  },
  {
    name: 'supply_stock-cards-flb',
    parentName: 'supply',
    list: SupplyStockCardsListFlb,
    show: SupplyStockCardsShowFlb,
    options: {
      label: 'Stock Cards Flb',
      route: 'stock-cards-flb',
    },
  },
  {
    name: 'supply_stock-cards-downstream',
    parentName: 'supply',
    list: SupplyStockCardsListDownstream,
    show: SupplyStockCardsShowDownstream,
    options: {
      label: 'Stock Cards Downstream',
      route: 'stock-cards-downstream',
    },
  },
  {
    name: 'supply_rmp',
    parentName: 'supply',
    list: SupplyRmpList,
    show: SupplyRmpShow,
    options: {
      label: 'RMP Activities',
      route: 'rmp',
    },
  },
  {
    name: 'supply_ar-ap-settlement',
    parentName: 'supply',
    list: ARAPSettlementList,
    show: ARAPSettlementShow,
    options: {
      label: 'AR/AP Tarik Ikan',
      route: 'ar-ap-settlement',
    },
  },
].filter(envModuleFlagVault);
