import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';
import { LoadingScreen } from 'components';

/* PLOP_INJECT_LOADABLE */
const EnumerationAcquisitionChannelsCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAcquisitionChannelsCreate" */ 'pages/enumeration/acquisitionChannels/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAcquisitionChannelsEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAcquisitionChannelsEdit" */ 'pages/enumeration/acquisitionChannels/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAcquisitionChannelsList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAcquisitionChannelsList" */ 'pages/enumeration/acquisitionChannels/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAcquisitionChannelsShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAcquisitionChannelsShow" */ 'pages/enumeration/acquisitionChannels/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentLabelsCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentLabelsCreate" */ 'pages/enumeration/adjustmentLabels/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentLabelsEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentLabelsEdit" */ 'pages/enumeration/adjustmentLabels/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentLabelsList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentLabelsList" */ 'pages/enumeration/adjustmentLabels/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentLabelsShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentLabelsShow" */ 'pages/enumeration/adjustmentLabels/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentTypesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentTypesCreate" */ 'pages/enumeration/adjustmentTypes/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentTypesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentTypesEdit" */ 'pages/enumeration/adjustmentTypes/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentTypesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentTypesList" */ 'pages/enumeration/adjustmentTypes/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationAdjustmentTypesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationAdjustmentTypesShow" */ 'pages/enumeration/adjustmentTypes/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTermOfPaymentsCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTermOfPaymentsCreate" */ 'pages/enumeration/buyerLeadTermOfPayments/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTermOfPaymentsEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTermOfPaymentsEdit" */ 'pages/enumeration/buyerLeadTermOfPayments/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTermOfPaymentsList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTermOfPaymentsList" */ 'pages/enumeration/buyerLeadTermOfPayments/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTermOfPaymentsShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTermOfPaymentsShow" */ 'pages/enumeration/buyerLeadTermOfPayments/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTiersCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTiersCreate" */ 'pages/enumeration/buyerLeadTiers/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTiersEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTiersEdit" */ 'pages/enumeration/buyerLeadTiers/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTiersList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTiersList" */ 'pages/enumeration/buyerLeadTiers/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationBuyerLeadTiersShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationBuyerLeadTiersShow" */ 'pages/enumeration/buyerLeadTiers/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCancellationReasonCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCancellationReasonCreate" */ 'pages/enumeration/cancellationReason/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCancellationReasonEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCancellationReasonEdit" */ 'pages/enumeration/cancellationReason/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCancellationReasonList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCancellationReasonList" */ 'pages/enumeration/cancellationReason/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCancellationReasonShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCancellationReasonShow" */ 'pages/enumeration/cancellationReason/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCategoriesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCategoriesCreate" */ 'pages/enumeration/categories/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCategoriesEdit = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationCategoriesEdit" */ 'pages/enumeration/categories/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCategoriesList = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationCategoriesList" */ 'pages/enumeration/categories/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCategoriesShow = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationCategoriesShow" */ 'pages/enumeration/categories/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCommodityNamesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCommodityNamesCreate" */ 'pages/enumeration/commodityNames/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCommodityNamesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCommodityNamesEdit" */ 'pages/enumeration/commodityNames/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCommodityNamesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCommodityNamesList" */ 'pages/enumeration/commodityNames/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCommodityNamesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCommodityNamesShow" */ 'pages/enumeration/commodityNames/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintPurposesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintPurposesCreate" */ 'pages/enumeration/complaintPurposes/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintPurposesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintPurposesEdit" */ 'pages/enumeration/complaintPurposes/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintPurposesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintPurposesList" */ 'pages/enumeration/complaintPurposes/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintPurposesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintPurposesShow" */ 'pages/enumeration/complaintPurposes/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintTypesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintTypesCreate" */ 'pages/enumeration/complaintTypes/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintTypesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintTypesEdit" */ 'pages/enumeration/complaintTypes/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintTypesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintTypesList" */ 'pages/enumeration/complaintTypes/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationComplaintTypesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationComplaintTypesShow" */ 'pages/enumeration/complaintTypes/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCustomerCareActivityTypesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityTypesCreate" */ 'pages/enumeration/customerCareActivityTypes/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCustomerCareActivityTypesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityTypesEdit" */ 'pages/enumeration/customerCareActivityTypes/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
// pelase repeat this pattern for all resources in this file
const EnumerationCustomerCareActivityTypesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityTypesList" */ 'pages/enumeration/customerCareActivityTypes/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCustomerCareActivityTypesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityTypesShow" */ 'pages/enumeration/customerCareActivityTypes/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCustomerCareActivityResponsesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityResponsesCreate" */ 'pages/enumeration/customerCareActivityResponses/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCustomerCareActivityResponsesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityResponsesEdit" */ 'pages/enumeration/customerCareActivityResponses/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCustomerCareActivityResponsesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityResponsesList" */ 'pages/enumeration/customerCareActivityResponses/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationCustomerCareActivityResponsesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationCustomerCareActivityResponsesShow" */ 'pages/enumeration/customerCareActivityResponses/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationDistributionChannelsCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationDistributionChannelsCreate" */ 'pages/enumeration/distributionChannels/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationDistributionChannelsEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationDistributionChannelsEdit" */ 'pages/enumeration/distributionChannels/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationDistributionChannelsList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationDistributionChannelsList" */ 'pages/enumeration/distributionChannels/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationDistributionChannelsShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationDistributionChannelsShow" */ 'pages/enumeration/distributionChannels/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationFinalStatusesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationFinalStatusesCreate" */ 'pages/enumeration/finalStatuses/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationFinalStatusesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationFinalStatusesEdit" */ 'pages/enumeration/finalStatuses/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationFinalStatusesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationFinalStatusesList" */ 'pages/enumeration/finalStatuses/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationFinalStatusesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationFinalStatusesShow" */ 'pages/enumeration/finalStatuses/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGlazesCreate = loadable(
  () => import(/* webpackChunkName: "EnumerationGlazesCreate" */ 'pages/enumeration/glazes/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGlazesEdit = loadable(
  () => import(/* webpackChunkName: "EnumerationGlazesEdit" */ 'pages/enumeration/glazes/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGlazesList = loadable(
  () => import(/* webpackChunkName: "EnumerationGlazesList" */ 'pages/enumeration/glazes/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGlazesShow = loadable(
  () => import(/* webpackChunkName: "EnumerationGlazesShow" */ 'pages/enumeration/glazes/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGradesCreate = loadable(
  () => import(/* webpackChunkName: "EnumerationGradesCreate" */ 'pages/enumeration/grades/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGradesEdit = loadable(
  () => import(/* webpackChunkName: "EnumerationGradesEdit" */ 'pages/enumeration/grades/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGradesList = loadable(
  () => import(/* webpackChunkName: "EnumerationGradesList" */ 'pages/enumeration/grades/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationGradesShow = loadable(
  () => import(/* webpackChunkName: "EnumerationGradesShow" */ 'pages/enumeration/grades/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationJobTitlesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationJobTitlesCreate" */ 'pages/enumeration/jobTitles/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationJobTitlesEdit = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationJobTitlesEdit" */ 'pages/enumeration/jobTitles/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationJobTitlesList = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationJobTitlesList" */ 'pages/enumeration/jobTitles/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationJobTitlesShow = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationJobTitlesShow" */ 'pages/enumeration/jobTitles/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationNextStepCoordinationsCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationNextStepCoordinationsCreate" */ 'pages/enumeration/nextStepCoordinations/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationNextStepCoordinationsEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationNextStepCoordinationsEdit" */ 'pages/enumeration/nextStepCoordinations/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationNextStepCoordinationsList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationNextStepCoordinationsList" */ 'pages/enumeration/nextStepCoordinations/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationNextStepCoordinationsShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationNextStepCoordinationsShow" */ 'pages/enumeration/nextStepCoordinations/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderRejectionReasonsCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationOrderRejectionReasonsCreate" */ 'pages/enumeration/orderRejectionReasons/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderRejectionReasonsEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationOrderRejectionReasonsEdit" */ 'pages/enumeration/orderRejectionReasons/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderRejectionReasonsList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationOrderRejectionReasonsList" */ 'pages/enumeration/orderRejectionReasons/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderRejectionReasonsShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationOrderRejectionReasonsShow" */ 'pages/enumeration/orderRejectionReasons/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderTypesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationOrderTypesCreate" */ 'pages/enumeration/orderTypes/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderTypesEdit = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationOrderTypesEdit" */ 'pages/enumeration/orderTypes/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderTypesList = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationOrderTypesList" */ 'pages/enumeration/orderTypes/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationOrderTypesShow = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationOrderTypesShow" */ 'pages/enumeration/orderTypes/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPackingSizesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationPackingSizesCreate" */ 'pages/enumeration/packingSizes/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPackingSizesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationPackingSizesEdit" */ 'pages/enumeration/packingSizes/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPackingSizesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationPackingSizesList" */ 'pages/enumeration/packingSizes/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPackingSizesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationPackingSizesShow" */ 'pages/enumeration/packingSizes/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPayCreate = loadable(
  () => import(/* webpackChunkName: "EnumerationPayCreate" */ 'pages/enumeration/pay/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPayEdit = loadable(
  () => import(/* webpackChunkName: "EnumerationPayEdit" */ 'pages/enumeration/pay/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPayList = loadable(
  () => import(/* webpackChunkName: "EnumerationPayList" */ 'pages/enumeration/pay/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPayShow = loadable(
  () => import(/* webpackChunkName: "EnumerationPayShow" */ 'pages/enumeration/pay/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPicInputsCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationPicInputsCreate" */ 'pages/enumeration/picInputs/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPicInputsEdit = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationPicInputsEdit" */ 'pages/enumeration/picInputs/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPicInputsList = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationPicInputsList" */ 'pages/enumeration/picInputs/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationPicInputsShow = loadable(
  () =>
    import(/* webpackChunkName: "EnumerationPicInputsShow" */ 'pages/enumeration/picInputs/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitInventoriesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitInventoriesCreate" */ 'pages/enumeration/unitInventories/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitInventoriesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitInventoriesEdit" */ 'pages/enumeration/unitInventories/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitInventoriesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitInventoriesList" */ 'pages/enumeration/unitInventories/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitInventoriesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitInventoriesShow" */ 'pages/enumeration/unitInventories/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitLoadDevicesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitLoadDevicesCreate" */ 'pages/enumeration/unitLoadDevices/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitLoadDevicesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitLoadDevicesEdit" */ 'pages/enumeration/unitLoadDevices/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitLoadDevicesList = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitLoadDevicesList" */ 'pages/enumeration/unitLoadDevices/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const EnumerationUnitLoadDevicesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "EnumerationUnitLoadDevicesShow" */ 'pages/enumeration/unitLoadDevices/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const Database = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Database'));

export const enumerationResources: ResourceProps[] = [
  {
    name: 'enumeration',
    icon: <Database size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'enumeration_acquisition-channels',
    parentName: 'enumeration',
    create: EnumerationAcquisitionChannelsCreate,
    edit: EnumerationAcquisitionChannelsEdit,
    list: EnumerationAcquisitionChannelsList,
    show: EnumerationAcquisitionChannelsShow,
    canDelete: true,
    options: {
      label: 'acquisition channels',
      route: 'acquisition-channels',
    },
  },
  {
    name: 'enumeration_adjustment-labels',
    parentName: 'enumeration',
    create: EnumerationAdjustmentLabelsCreate,
    edit: EnumerationAdjustmentLabelsEdit,
    list: EnumerationAdjustmentLabelsList,
    show: EnumerationAdjustmentLabelsShow,
    options: {
      label: 'Adjustment Labels',
      route: 'adjustment-labels',
    },
  },
  {
    name: 'enumeration_adjustment-types',
    parentName: 'enumeration',
    create: EnumerationAdjustmentTypesCreate,
    edit: EnumerationAdjustmentTypesEdit,
    list: EnumerationAdjustmentTypesList,
    show: EnumerationAdjustmentTypesShow,
    options: {
      label: 'Adjustment types',
      route: 'adjustment-types',
    },
  },

  {
    name: 'enumeration_buyer-lead-term-of-payments',
    parentName: 'enumeration',
    create: EnumerationBuyerLeadTermOfPaymentsCreate,
    edit: EnumerationBuyerLeadTermOfPaymentsEdit,
    list: EnumerationBuyerLeadTermOfPaymentsList,
    show: EnumerationBuyerLeadTermOfPaymentsShow,
    canDelete: true,
    options: {
      label: 'buyer lead term of payments',
      route: 'buyer-lead-term-of-payments',
    },
  },
  {
    name: 'enumeration_buyer-lead-tiers',
    parentName: 'enumeration',
    create: EnumerationBuyerLeadTiersCreate,
    edit: EnumerationBuyerLeadTiersEdit,
    list: EnumerationBuyerLeadTiersList,
    show: EnumerationBuyerLeadTiersShow,
    canDelete: true,
    options: {
      label: 'buyer lead tiers',
      route: 'buyer-lead-tiers',
    },
  },
  {
    name: 'enumeration_cancellation-reason',
    parentName: 'enumeration',
    create: EnumerationCancellationReasonCreate,
    edit: EnumerationCancellationReasonEdit,
    list: EnumerationCancellationReasonList,
    show: EnumerationCancellationReasonShow,
    options: {
      label: 'Cancellation reason',
      route: 'cancellation-reason',
    },
  },
  {
    name: 'enumeration_categories',
    parentName: 'enumeration',
    create: EnumerationCategoriesCreate,
    edit: EnumerationCategoriesEdit,
    list: EnumerationCategoriesList,
    show: EnumerationCategoriesShow,
    canDelete: true,
    options: {
      label: 'categories',
      route: 'categories',
    },
  },
  {
    name: 'enumeration_commodity-names',
    parentName: 'enumeration',
    create: EnumerationCommodityNamesCreate,
    edit: EnumerationCommodityNamesEdit,
    list: EnumerationCommodityNamesList,
    show: EnumerationCommodityNamesShow,
    canDelete: true,
    options: {
      label: 'commodity names',
      route: 'commodity-names',
    },
  },
  {
    name: 'enumeration_complaint-purposes',
    parentName: 'enumeration',
    create: EnumerationComplaintPurposesCreate,
    edit: EnumerationComplaintPurposesEdit,
    list: EnumerationComplaintPurposesList,
    show: EnumerationComplaintPurposesShow,
    canDelete: true,
    options: {
      label: 'Complaint Purposes',
      route: 'complaint-purposes',
    },
  },
  {
    name: 'enumeration_complaint-types',
    parentName: 'enumeration',
    create: EnumerationComplaintTypesCreate,
    edit: EnumerationComplaintTypesEdit,
    list: EnumerationComplaintTypesList,
    show: EnumerationComplaintTypesShow,
    canDelete: true,
    options: {
      label: 'Complaint Types',
      route: 'complaint-types',
    },
  },
  {
    name: 'enumeration_customer-care-activity-types',
    parentName: 'enumeration',
    create: EnumerationCustomerCareActivityTypesCreate,
    edit: EnumerationCustomerCareActivityTypesEdit,
    list: EnumerationCustomerCareActivityTypesList,
    show: EnumerationCustomerCareActivityTypesShow,
    canDelete: true,
    options: {
      label: 'customer care activity types',
      route: 'customer-care-activity-types',
    },
  },
  {
    name: 'enumeration_customer-care-activity-responses',
    parentName: 'enumeration',
    create: EnumerationCustomerCareActivityResponsesCreate,
    edit: EnumerationCustomerCareActivityResponsesEdit,
    list: EnumerationCustomerCareActivityResponsesList,
    show: EnumerationCustomerCareActivityResponsesShow,
    canDelete: true,
    options: {
      label: 'customer care activity responses',
      route: 'customer-care-activity-responses',
    },
  },
  {
    name: 'enumeration_distribution-channels',
    parentName: 'enumeration',
    create: EnumerationDistributionChannelsCreate,
    edit: EnumerationDistributionChannelsEdit,
    list: EnumerationDistributionChannelsList,
    show: EnumerationDistributionChannelsShow,
    canDelete: true,
    options: {
      label: 'distribution channels',
      route: 'distribution-channels',
    },
  },
  {
    name: 'enumeration_final-statuses',
    parentName: 'enumeration',
    create: EnumerationFinalStatusesCreate,
    edit: EnumerationFinalStatusesEdit,
    list: EnumerationFinalStatusesList,
    show: EnumerationFinalStatusesShow,
    canDelete: true,
    options: {
      label: 'Final Statuses',
      route: 'final-statuses',
    },
  },
  {
    name: 'enumeration_glazes',
    parentName: 'enumeration',
    create: EnumerationGlazesCreate,
    edit: EnumerationGlazesEdit,
    list: EnumerationGlazesList,
    show: EnumerationGlazesShow,
    canDelete: true,
    options: {
      label: 'glazes',
      route: 'glazes',
    },
  },
  {
    name: 'enumeration_grades',
    parentName: 'enumeration',
    create: EnumerationGradesCreate,
    edit: EnumerationGradesEdit,
    list: EnumerationGradesList,
    show: EnumerationGradesShow,
    canDelete: true,
    options: {
      label: 'grades',
      route: 'grades',
    },
  },
  {
    name: 'enumeration_job-titles',
    parentName: 'enumeration',
    create: EnumerationJobTitlesCreate,
    edit: EnumerationJobTitlesEdit,
    list: EnumerationJobTitlesList,
    show: EnumerationJobTitlesShow,
    canDelete: true,
    options: {
      label: 'Job titles',
      route: 'job-titles',
    },
  },
  {
    name: 'enumeration_next-step-coordinations',
    parentName: 'enumeration',
    create: EnumerationNextStepCoordinationsCreate,
    edit: EnumerationNextStepCoordinationsEdit,
    list: EnumerationNextStepCoordinationsList,
    show: EnumerationNextStepCoordinationsShow,
    canDelete: true,
    options: {
      label: 'Next Step Coordinations',
      route: 'next-step-coordinations',
    },
  },
  {
    name: 'enumeration_order-rejection-reasons',
    parentName: 'enumeration',
    create: EnumerationOrderRejectionReasonsCreate,
    edit: EnumerationOrderRejectionReasonsEdit,
    list: EnumerationOrderRejectionReasonsList,
    show: EnumerationOrderRejectionReasonsShow,
    options: {
      label: 'Order rejection reasons',
      route: 'order-rejection-reasons',
    },
  },
  {
    name: 'enumeration_order-types',
    parentName: 'enumeration',
    create: EnumerationOrderTypesCreate,
    edit: EnumerationOrderTypesEdit,
    list: EnumerationOrderTypesList,
    show: EnumerationOrderTypesShow,
    options: {
      label: 'Order types',
      route: 'order-types',
    },
  },
  {
    name: 'enumeration_packing-sizes',
    parentName: 'enumeration',
    create: EnumerationPackingSizesCreate,
    edit: EnumerationPackingSizesEdit,
    list: EnumerationPackingSizesList,
    show: EnumerationPackingSizesShow,
    canDelete: true,
    options: {
      label: 'packing sizes',
      route: 'packing-sizes',
    },
  },
  {
    name: 'enumeration_pay',
    parentName: 'enumeration',
    create: EnumerationPayCreate,
    edit: EnumerationPayEdit,
    list: EnumerationPayList,
    show: EnumerationPayShow,
    options: {
      label: 'Pay With',
      route: 'pay',
    },
  },
  {
    name: 'enumeration_pic-inputs',
    parentName: 'enumeration',
    create: EnumerationPicInputsCreate,
    edit: EnumerationPicInputsEdit,
    list: EnumerationPicInputsList,
    show: EnumerationPicInputsShow,
    canDelete: true,
    options: {
      label: 'PIC inputs',
      route: 'pic-inputs',
    },
  },
  {
    name: 'enumeration_unit-inventories',
    parentName: 'enumeration',
    create: EnumerationUnitInventoriesCreate,
    edit: EnumerationUnitInventoriesEdit,
    list: EnumerationUnitInventoriesList,
    show: EnumerationUnitInventoriesShow,
    canDelete: true,
    options: {
      label: 'unit inventories',
      route: 'unit-inventories',
    },
  },
  {
    name: 'enumeration_unit-load-device',
    parentName: 'enumeration',
    create: EnumerationUnitLoadDevicesCreate,
    edit: EnumerationUnitLoadDevicesEdit,
    list: EnumerationUnitLoadDevicesList,
    show: EnumerationUnitLoadDevicesShow,
    options: {
      label: 'Unit load devices',
      route: 'unit-load-devices',
    },
  },
].filter(envModuleFlagVault);
