import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_LOADABLE */
const PricingBasedPriceFlbEdit = loadable(
  () =>
    import(/* webpackChunkName: "PricingBasedPriceFlbEdit" */ 'pages/pricing/basedPriceFLB/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBasedPriceFlbList = loadable(
  () =>
    import(/* webpackChunkName: "PricingBasedPriceFlbList" */ 'pages/pricing/basedPriceFLB/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBasedPriceFlbShow = loadable(
  () =>
    import(/* webpackChunkName: "PricingBasedPriceFlbShow" */ 'pages/pricing/basedPriceFLB/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBasedPriceSlbCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingBasedPriceSlbCreate" */ 'pages/pricing/basedPriceSLB/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBasedPriceSlbList = loadable(
  () =>
    import(/* webpackChunkName: "PricingBasedPriceSlbList" */ 'pages/pricing/basedPriceSLB/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBasedPriceSlbShow = loadable(
  () =>
    import(/* webpackChunkName: "PricingBasedPriceSlbShow" */ 'pages/pricing/basedPriceSLB/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingFirstLevelPricesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingFirstLevelPricesCreate" */ 'pages/pricing/firstLevelPrices/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingFirstLevelPricesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingFirstLevelPricesEdit" */ 'pages/pricing/firstLevelPrices/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingFirstLevelPricesList = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingFirstLevelPricesList" */ 'pages/pricing/firstLevelPrices/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingFirstLevelPricesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingFirstLevelPricesShow" */ 'pages/pricing/firstLevelPrices/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBottomPricesDownstreamList = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingBottomPricesDownstreamList" */ 'pages/pricing/bottomPricesDownstream/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBottomPricesDownstreamShow = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingBottomPricesDownstreamShow" */ 'pages/pricing/bottomPricesDownstream/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBottomPricesDownstreamEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingBottomPricesDownstreamEdit" */ 'pages/pricing/bottomPricesDownstream/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingBottomPricesDownstreamCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingBottomPricesDownstreamCreate" */ 'pages/pricing/bottomPricesDownstream/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingPublishedPricesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingPublishedPricesCreate" */ 'pages/pricing/publishedPrices/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingPublishedPricesList = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingPublishedPricesList" */ 'pages/pricing/publishedPrices/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingPublishedPricesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingPublishedPricesShow" */ 'pages/pricing/publishedPrices/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingPublishedPricesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingPublishedPricesEdit" */ 'pages/pricing/publishedPrices/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingLogPublishPricesList = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingLogPublishPricesList" */ 'pages/pricing/logPublishPrices/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingLogPublishPricesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingLogPublishPricesShow" */ 'pages/pricing/logPublishPrices/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingLogPublishPricesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "PricingLogPublishPricesCreate" */ 'pages/pricing/logPublishPrices/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const PricingPricingDatasList = loadable(
  () => import(/* webpackChunkName: "PricingPricingDatasList" */ 'pages/pricing/pricingDatas/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const CurrencyCircleDollar = loadable(
  () => import('@onefish/icons-react/src/icons/phosphor/CurrencyCircleDollar'),
);

export const pricingResources: ResourceProps[] = [
  {
    name: 'pricing',
    icon: <CurrencyCircleDollar size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'pricing_based-prices',
    parentName: 'pricing',
    create: PricingBasedPriceSlbCreate,
    list: PricingBasedPriceSlbList,
    show: PricingBasedPriceSlbShow,
    options: {
      label: 'Based Price SLB',
      route: 'based-price-slb',
    },
  },
  {
    name: 'pricing_based-price-flb',
    parentName: 'pricing',
    list: PricingBasedPriceFlbList,
    show: PricingBasedPriceFlbShow,
    edit: PricingBasedPriceFlbEdit,
    options: {
      label: 'Based Price FLB',
      route: 'based-price-flb',
    },
  },
  {
    name: 'pricing_bottom-prices-downstream',
    parentName: 'pricing',
    list: PricingBottomPricesDownstreamList,
    show: PricingBottomPricesDownstreamShow,
    edit: PricingBottomPricesDownstreamEdit,
    create: PricingBottomPricesDownstreamCreate,
    options: {
      label: 'Bottom Prices Downstream',
      route: 'bottom-prices-downstream',
    },
  },
  {
    name: 'pricing_first-level-prices',
    parentName: 'pricing',
    create: PricingFirstLevelPricesCreate,
    edit: PricingFirstLevelPricesEdit,
    list: PricingFirstLevelPricesList,
    show: PricingFirstLevelPricesShow,
    canDelete: true,
    options: {
      label: 'First level prices',
      route: 'first-level-prices',
    },
  },
  {
    name: 'pricing_published-prices',
    parentName: 'pricing',
    create: PricingPublishedPricesCreate,
    list: PricingPublishedPricesList,
    show: PricingPublishedPricesShow,
    edit: PricingPublishedPricesEdit,
    canDelete: true,
    options: {
      label: 'Published prices',
      route: 'published-prices',
    },
  },
  {
    name: 'pricing_log-published-prices',
    parentName: 'pricing',
    list: PricingLogPublishPricesList,
    show: PricingLogPublishPricesShow,
    create: PricingLogPublishPricesCreate,
    options: {
      label: 'Log Publish Prices',
      route: 'log-published-prices',
    },
  },
  {
    name: 'pricing_pricing-datas',
    parentName: 'pricing',
    list: PricingPricingDatasList,
    options: {
      label: 'Pricing Datas',
      route: 'pricing-datas',
    },
  },
].filter(envModuleFlagVault);
