import { AxiosInstance } from 'axios';
import { CrudFilter, DataProvider } from '@pankod/refine-core';
import { dataProvider } from './dataProvider';
import { axiosInstance } from 'libs/utils';
import { SERVICE } from 'config/service';

const handlerCustomQuery = (filter: CrudFilter[] | undefined) => {
  const params = {} as any;
  filter?.forEach((con: any) => {
    if (con?.field) {
      params[con.field] = con.value;
    }
  });
  return params;
};

const crmProvider = (apiUrl: string, httpClient: AxiosInstance = axiosInstance): DataProvider => {
  const defaultDataProvider = dataProvider(apiUrl, httpClient);

  return {
    ...defaultDataProvider,
    getList: async (props) => {
      const { resource, metaData, filters } = props;
      const url = `${SERVICE.CRM}/${resource}`;
      let params = metaData?.params || {};

      if (metaData?.customQuery) {
        params = { ...params, ...handlerCustomQuery(filters) };
      }

      const res = await httpClient.get(`${url}`, {
        params,
        // Temporary due get error CORS for each request to svc-crm
        // TODO: Remove this
        transformRequest: (data, headers: any) => {
          delete headers?.['X-Client-Version'];
          delete headers?.['X-Client-Id'];
          return data;
        },
      });

      const data = res?.data?.data;
      const total = res?.data?.pagination?.total_datas ?? data?.length;

      return {
        data,
        total,
      };
    },
    getOne: async (props) => {
      const { resource, id } = props;
      if (!id) return { data: null };
      const url = `${SERVICE.CRM}${resource}`;

      const res = await httpClient.get(`${url}`, {
        // params: metaData?.params,
        // Temporary due get error CORS for each request to svc-crm
        // TODO: Remove this
        transformRequest: (data, headers: any) => {
          delete headers?.['X-Client-Version'];
          delete headers?.['X-Client-Id'];
          return data;
        },
      });
      const data = res?.data?.data;

      return { data };
    },
  };
};

export { crmProvider };
