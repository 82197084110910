import loadable from '@loadable/component';
import { RefineRouteProps } from '@pankod/refine-react-router-v6';
import { LoadingScreen } from 'components';
import { FEATURE } from 'config/feature';

const SalesDownstreamOrderHistoryV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "SalesDownstreamOrderHistoryV2" */ 'pages/sales/downstreamOrder/history'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const SalesDownstreamOrderHistory = loadable(
  () =>
    import(
      /* webpackChunkName: "SalesDownstreamOrderHistory" */ 'pages/sales/downstream-order/history'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const SalesOrderHistory = loadable(
  () => import(/* webpackChunkName: "SalesOrderHistory" */ 'pages/sales/order/history'),
  {
    fallback: <LoadingScreen />,
  },
);

const BuyerLeadsHistory = loadable(
  () => import(/* webpackChunkName: "BuyerLeadsHistory" */ 'pages/buyer/leads/history'),
  {
    fallback: <LoadingScreen />,
  },
);

const ProductHistory = loadable(
  () => import(/* webpackChunkName: "ProductHistory" */ 'pages/core/products/history'),
  {
    fallback: <LoadingScreen />,
  },
);

const FarmerLeadsHistory = loadable(
  () => import(/* webpackChunkName: "FarmerLeadsHistory" */ 'pages/farmer/leads/history'),
  {
    fallback: <LoadingScreen />,
  },
);

const FarmerPondsHistory = loadable(
  () => import(/* webpackChunkName: "FarmerPondsHistory" */ 'pages/farmer/ponds/history'),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementStockAdjustmentHistory = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementStockAdjustmentHistory" */ 'pages/hubManagement/stockManagement/stockAdjustment/history'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const HubManagementInboundManagementHistory = loadable(
  () =>
    import(
      /* webpackChunkName: "HubManagementInboundManagementHistory" */ 'pages/hubManagement/inboundManagement/inboundRequest/history'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const MerchandiserPurchaseOrderHistory = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserPurchaseOrderHistory" */ 'pages/merchandiser/purchaseOrderNew/history'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionPricingAreaHistory = loadable(
  () =>
    import(/* webpackChunkName: "RegionPricingAreaHistory" */ 'pages/region/pricingAreas/history'),
  {
    fallback: <LoadingScreen />,
  },
);

const SettingSupplyPointHistory = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingSupplyPointHistory" */ 'pages/setting/supplyPoints/history'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const historyRoutes: RefineRouteProps[] = [
  {
    element: <SalesOrderHistory />,
    path: '/sales_orders/show/:id/history',
    layout: true,
  },
  {
    element: <SalesDownstreamOrderHistoryV2 />,
    path: '/sales/downstream-order-v2/show/:id/history',
    layout: true,
  },
  {
    element: <SalesDownstreamOrderHistory />,
    path: '/sales/downstream-orders/show/:id/history',
    layout: true,
  },
  {
    element: <RegionPricingAreaHistory />,
    path: '/region/pricing-areas/show/:id/history',
    layout: true,
  },
  {
    element: <SettingSupplyPointHistory />,
    path: '/setting/supply-points/show/:id/history',
    layout: true,
  },
  {
    element: <FarmerLeadsHistory />,
    path: '/farmer/leads/show/:id/history',
    layout: true,
  },
  {
    element: <FarmerPondsHistory />,
    path: '/farmer/ponds/show/:id/history',
    layout: true,
  },
  FEATURE.ENABLE_FEATURE_BUYER_LEADS_HISTORY
    ? {
        element: <BuyerLeadsHistory />,
        path: '/buyer/leads/show/:id/history',
        layout: true,
      }
    : {},
  {
    element: <HubManagementInboundManagementHistory />,
    path: '/hub-management/inbound-management/inbound-request/show/:id/history',
    layout: true,
  },
  {
    element: <HubManagementStockAdjustmentHistory />,
    path: '/hub-management/stock-management/stock-adjustment/show/:id/history',
    layout: true,
  },
  {
    element: <MerchandiserPurchaseOrderHistory />,
    path: '/merchandiser/purchase-order/show/:id/history',
    layout: true,
  },
  {
    element: <ProductHistory />,
    path: '/core/products/show/:id/history',
    layout: true,
  },
];

export default historyRoutes;
