import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';
import { LoadingScreen } from 'components';

/* PLOP_INJECT_LOADABLE */
const CoreCommodityCategoriesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "CoreCommodityCategoriesCreate" */ 'pages/core/commodityCategories/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreCommodityCategoriesEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "CoreCommodityCategoriesEdit" */ 'pages/core/commodityCategories/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreCommodityCategoriesList = loadable(
  () =>
    import(
      /* webpackChunkName: "CoreCommodityCategoriesList" */ 'pages/core/commodityCategories/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreCommodityCategoriesShow = loadable(
  () =>
    import(
      /* webpackChunkName: "CoreCommodityCategoriesShow" */ 'pages/core/commodityCategories/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductsCreate = loadable(
  () => import(/* webpackChunkName: "CoreProductsCreate" */ 'pages/core/products/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductsEdit = loadable(
  () => import(/* webpackChunkName: "CoreProductsEdit" */ 'pages/core/products/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductsList = loadable(
  () => import(/* webpackChunkName: "CoreProductsList" */ 'pages/core/products/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductsShow = loadable(
  () => import(/* webpackChunkName: "CoreProductsShow" */ 'pages/core/products/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductTemplatesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "CoreProductTemplatesCreate" */ 'pages/core/productTemplates/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductTemplatesEdit = loadable(
  () =>
    import(/* webpackChunkName: "CoreProductTemplatesEdit" */ 'pages/core/productTemplates/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductTemplatesList = loadable(
  () =>
    import(/* webpackChunkName: "CoreProductTemplatesList" */ 'pages/core/productTemplates/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const CoreProductTemplatesShow = loadable(
  () =>
    import(/* webpackChunkName: "CoreProductTemplatesShow" */ 'pages/core/productTemplates/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const Package = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Package'));

export const coreResources: ResourceProps[] = [
  {
    name: 'core',
    icon: <Package size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'core_commodity-categories',
    parentName: 'core',
    create: CoreCommodityCategoriesCreate,
    edit: CoreCommodityCategoriesEdit,
    list: CoreCommodityCategoriesList,
    show: CoreCommodityCategoriesShow,
    canDelete: true,
    options: {
      label: 'commodity categories',
      route: 'commodity-categories',
    },
  },
  {
    name: 'core_products',
    parentName: 'core',
    create: CoreProductsCreate,
    edit: CoreProductsEdit,
    list: CoreProductsList,
    show: CoreProductsShow,
    canDelete: true,
    options: {
      label: 'products',
      route: 'products',
    },
  },
  {
    name: 'core_product-templates',
    parentName: 'core',
    create: CoreProductTemplatesCreate,
    edit: CoreProductTemplatesEdit,
    list: CoreProductTemplatesList,
    show: CoreProductTemplatesShow,
    canDelete: true,
    options: {
      label: 'product templates',
      route: 'product-templates',
    },
  },
].filter(envModuleFlagVault);
