import React from 'react';
import * as Sentry from '@sentry/react';
import loadable from '@loadable/component';

const FallbackError = loadable(
  () => import(/* webpackChunkName: "FallbackError" */ './FallbackError'),
);

export const ErrorBoundary: React.FC<Sentry.ErrorBoundaryProps> = ({ children, fallback }) => (
  <Sentry.ErrorBoundary
    showDialog
    dialogOptions={{
      title: 'Mohon maaf, terjadi kesalahan pada system.',
      subtitle: 'Tim kami telah diberitahu.',
      subtitle2: 'Jika Anda ingin membantu, beri tahu kami apa yang terjadi di bawah.',
      labelName: 'Nama',
      labelEmail: 'Email',
      labelComments: 'Jelaskan, bagaimana bisa terjadi',
    }}
    fallback={
      fallback
        ? fallback
        : ({ error, componentStack, resetError }) => (
            <FallbackError error={error} componentStack={componentStack} resetError={resetError} />
          )
    }
  >
    {children}
  </Sentry.ErrorBoundary>
);
