import loadable from '@loadable/component';
import { Button } from '@pankod/refine-antd';

export interface IFilterButton {
  show: () => void;
  extra?: React.ReactNode | any;
  testId?: string;
}

const Sliders = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Sliders'));

export const FilterButton: React.FC<IFilterButton> = ({ show, extra, testId }) => {
  const isFiltered = extra?.props?.count;

  return (
    <Button
      data-testid={testId}
      icon={
        <Sliders weight={isFiltered ? 'fill' : 'regular'} size="16" style={{ marginRight: 4 }} />
      }
      onClick={show}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isFiltered ? '#E4F4F1' : '#ffffff',
        borderColor: isFiltered ? '#9ACFC2' : '#d9d9d9',
      }}
    >
      <span style={{ marginRight: 5 }}>Filter</span> {extra}
    </Button>
  );
};
