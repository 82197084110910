import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_LOADABLE */
const UserExtendsList = loadable(
  () => import(/* webpackChunkName: "UserExtendsList" */ 'pages/user/extends/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const UserExtendsShow = loadable(
  () => import(/* webpackChunkName: "UserExtendsShow" */ 'pages/user/extends/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const UserExtendsCreate = loadable(
  () => import(/* webpackChunkName: "UserExtendsCreate" */ 'pages/user/extends/create'),
  {
    fallback: <LoadingScreen />,
  },
);

const UserExtendsEdit = loadable(
  () => import(/* webpackChunkName: "UserExtendsEdit" */ 'pages/user/extends/edit'),
  {
    fallback: <LoadingScreen />,
  },
);

const UserCircle = loadable(() => import('@onefish/icons-react/src/icons/phosphor/UserCircle'));

export const userResources: ResourceProps[] = [
  {
    name: 'user',
    icon: <UserCircle size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'user_extends',
    parentName: 'user',
    create: UserExtendsCreate,
    edit: UserExtendsEdit,
    list: UserExtendsList,
    show: UserExtendsShow,
    canDelete: true,
    options: {
      label: 'Extends',
      route: 'extends',
    },
  },
].filter(envModuleFlagVault);
