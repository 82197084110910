import { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth } from '@efishery/sdk-reactjs';
import { Row, Spin } from '@pankod/refine-antd';
import { ENV } from '@config/env';

export default function Login() {
  const auth = useAuth();

  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = queryParams.get('to');
  const error = queryParams.get('error');

  useEffect(() => {
    if (redirectTo) window.localStorage.setItem('to', redirectTo);

    auth.login({
      content: {
        footerNotes: ENV.APP_VERSION,
        notifNotes: error || undefined,
      },
    });
  }, [auth]);

  return (
    <Row justify="center" align="middle" style={{ height: '100vh', textAlign: 'center' }}>
      <Spin size="large" indicator={<LoadingOutlined spin />} />
    </Row>
  );
}
