import {
  CrudFilter,
  CrudFilters,
  CrudOperators,
  CrudSorting,
  getDefaultFilter,
  LogicalFilter,
} from '@pankod/refine-core';
import { stringify } from 'query-string';
import { axiosInstance } from 'libs/utils';
import { IShrimpHarvestProvider, MethodTypes } from '@interfaces/shrimpHarvestProvider.model';

const mapOperatorShrimpHarvest = (operator: CrudOperators): string => {
  switch (operator) {
    case 'ne':
    case 'gte':
    case 'lte':
    case 'in':
      return `_${operator}`;
    case 'contains':
      return '_like';
    case 'nin':
      return '_not_in';
    default:
      return ''; // default "eq"
  }
};
const generateSortShrimpHarvest = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = [];
    sort.forEach(({ field, order }) => {
      _sort.push(`${field} ${order}`);
    });
    return {
      _sort,
    };
  }
};
const generateFilterShrimpHarvest = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};
  if (filters) {
    filters.forEach((filter) => {
      if (isLogicalCrudFilterShrimpHarvest(filter)) {
        const { field, operator, value } = filter;
        if (field === 'q') {
          queryFilters._q = value;
          return;
        }
        const mappedOperator = mapOperatorShrimpHarvest(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
      }
    });
  }
  return queryFilters;
};
export const convertResourceToEndpointShrimpHarvest = (resources: string) => {
  return resources.split('_').join('/').toLocaleLowerCase();
};
const isLogicalCrudFilterShrimpHarvest = (filter: CrudFilter): filter is LogicalFilter => {
  return (filter as LogicalFilter).field !== undefined;
};

function getNestedPropertyWithArray(obj: any, keys: string[]) {
  let currentObj = obj;
  for (const key of keys) {
    currentObj = currentObj[key];
  }
  return currentObj;
}

export const shrimpHarvestProvider = (
  apiUrl: string,
  httpClient: typeof axiosInstance = axiosInstance,
): IShrimpHarvestProvider => ({
  getList: async ({ resource, filters, sort, metaData }) => {
    const {
      headers: headersFromMeta,
      method,
      url: metaUrl,
      blacklist: metaBlacklist,
      arrayFormat,
      embed,
    } = metaData ?? {};
    const endpoint = metaUrl ?? convertResourceToEndpointShrimpHarvest(resource);
    const url = `${apiUrl}/${endpoint}`;
    const blacklist: string = getDefaultFilter(metaBlacklist, filters);
    const requestMethod = (method as MethodTypes) ?? 'get';
    const filteredFilters = filters?.filter((filter) => {
      if (
        metaBlacklist &&
        isLogicalCrudFilterShrimpHarvest(filter) &&
        filter.field === metaBlacklist
      ) {
        return blacklist !== 'false';
      }
      return true;
    });
    const queryFilters = generateFilterShrimpHarvest(filteredFilters);
    const querySort = generateSortShrimpHarvest(sort);
    let query: {
      _page?: number;
      _limit?: number;
      _embed?: string;
      _sort?: string;
    } = {};

    query = {
      ...query,
      _embed: embed,
      ...queryFilters,
    };
    if (querySort) {
      const { _sort } = querySort;
      query._sort = _sort.join(',');
    }

    const { data } = await httpClient[requestMethod](
      `${url}?${stringify(query, { arrayFormat: arrayFormat || 'none' })}`,
      {
        headers: headersFromMeta,
      },
    );

    return {
      data: metaData?.dataAttrNames
        ? getNestedPropertyWithArray(data, metaData.dataAttrNames)
        : data?.data,
      total: metaData?.paginationAttrNames
        ? getNestedPropertyWithArray(data, metaData.paginationAttrNames)
        : data?.pagination?.total_elements ?? data?.data?.length,
    };
  },
});
