import { MODULES } from '@config/modules';
import { ResourceProps } from '@pankod/refine-core';

export const envModules = (env: Record<string, boolean>) => {
  const childs = Object.keys(env)
    .filter((env: string) => env.startsWith('ENABLE_MODULE_'))
    .map((module) => env[module] && module.replace('ENABLE_MODULE_', '').toLowerCase());

  const parents = childs
    .filter(Boolean)
    .filter((child) => (child as string).includes('_'))
    .map((parent) => (parent as string).split('_')[0]);

  return [...parents, ...childs];
};

export const envModuleFlagVault = (resource: ResourceProps): boolean => {
  const modules = envModules(MODULES);

  let activeModule = false;

  for (const module of modules) {
    if (module === resource.name) {
      activeModule = true;
      break;
    }
  }

  return activeModule;
};

export const setPolicy = (permissions: Array<string[]>) => {
  if (!permissions) return '';

  const policy = permissions.reduce((result: string, subject: string[]) => {
    const str = subject.join(', ');
    result += `${str}\n`;
    return result;
  }, ``);

  return policy;
};
