import React from 'react';

import { LayoutProps, useResource } from '@pankod/refine-core';
import { AntdLayout, Grid } from '@pankod/refine-antd';

import { ENV } from 'config/env';

import { useFlagging } from 'hooks/useFlagging';
import loadable from '@loadable/component';

const Maintenance = loadable(() => import(/* webpackChunkName: "Maintenance" */ '../maintenance'));

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
}) => {
  const breakpoint = Grid.useBreakpoint();

  const { resourceName } = useResource();
  const flag = useFlagging({
    flagId: ENV.FLAGRID_MAINTENANCE,
    entityContext: { resources: resourceName },
  });
  const isMaintenance = flag?.data?.variantKey === 'active';

  return (
    <AntdLayout hasSider style={{ minHeight: '100vh', flexDirection: 'row' }}>
      {Sider && <Sider />}
      <AntdLayout
        style={{
          display: 'flex',
          flex: 'auto',
          flexDirection: 'column',
          minHeight: '100vh',
          position: 'relative',
          width: 0,
        }}
      >
        {Header && <Header />}
        <AntdLayout.Content style={{ overflow: 'initial' }}>
          <div
            style={{
              padding: breakpoint.sm ? 24 : 12,
              minHeight: 360,
            }}
          >
            {isMaintenance ? <Maintenance /> : children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};
