import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';
import { LoadingScreen } from 'components';

/* PLOP_INJECT_LOADABLE */
const B2bCustomerList = loadable(
  () => import(/* webpackChunkName: "B2bCustomerList" */ 'pages/b2b/customer/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bCustomerShow = loadable(
  () => import(/* webpackChunkName: "B2bCustomerShow" */ 'pages/b2b/customer/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bSalesList = loadable(
  () => import(/* webpackChunkName: "B2bSalesList" */ 'pages/b2b/sales/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bSalesShow = loadable(
  () => import(/* webpackChunkName: "B2bSalesShow" */ 'pages/b2b/sales/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bSalesEdit = loadable(
  () => import(/* webpackChunkName: "B2bSalesEdit" */ 'pages/b2b/sales/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bSalesCreate = loadable(
  () => import(/* webpackChunkName: "B2bSalesCreate" */ 'pages/b2b/sales/create'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bRegionList = loadable(
  () => import(/* webpackChunkName: "B2bRegionList" */ 'pages/b2b/region/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bRegionShow = loadable(
  () => import(/* webpackChunkName: "B2bRegionShow" */ 'pages/b2b/region/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bRegionCreate = loadable(
  () => import(/* webpackChunkName: "B2bRegionCreate" */ 'pages/b2b/region/create'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bRegionEdit = loadable(
  () => import(/* webpackChunkName: "B2bRegionEdit" */ 'pages/b2b/region/edit'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bSupplierCreate = loadable(
  () => import(/* webpackChunkName: "B2bSupplierCreate" */ 'pages/b2b/supplier/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bSupplierEdit = loadable(
  () => import(/* webpackChunkName: "B2bSupplierEdit" */ 'pages/b2b/supplier/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bSupplierList = loadable(
  () => import(/* webpackChunkName: "B2bSupplierList" */ 'pages/b2b/supplier/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const B2bSupplierShow = loadable(
  () => import(/* webpackChunkName: "B2bSupplierShow" */ 'pages/b2b/supplier/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bPointList = loadable(
  () => import(/* webpackChunkName: "B2bPointList" */ 'pages/b2b/point/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bPointShow = loadable(
  () => import(/* webpackChunkName: "B2bPointShow" */ 'pages/b2b/point/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const B2bPointEdit = loadable(
  () => import(/* webpackChunkName: "B2bPointEdit" */ 'pages/b2b/point/edit'),
  {
    fallback: <LoadingScreen />,
  },
);

const Bag = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Bag'));

export const b2bResources: ResourceProps[] = [
  {
    name: 'b2b',
    icon: <Bag size={18} />,
    options: {
      label: 'B2B',
    },
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'b2b_sales',
    parentName: 'b2b',
    create: B2bSalesCreate,
    edit: B2bSalesEdit,
    list: B2bSalesList,
    show: B2bSalesShow,
    options: {
      label: 'Sales',
      route: 'sales',
    },
  },
  {
    name: 'b2b_supplier',
    parentName: 'b2b',
    create: B2bSupplierCreate,
    edit: B2bSupplierEdit,
    list: B2bSupplierList,
    show: B2bSupplierShow,
    options: {
      label: 'Supplier',
      route: 'supplier',
    },
  },
  {
    name: 'b2b_customer',
    parentName: 'b2b',
    list: B2bCustomerList,
    show: B2bCustomerShow,
    options: {
      label: 'Daftar Customer',
      route: 'customers',
    },
  },
  {
    name: 'b2b_region',
    parentName: 'b2b',
    list: B2bRegionList,
    show: B2bRegionShow,
    create: B2bRegionCreate,
    edit: B2bRegionEdit,
    options: {
      label: 'Region',
      route: 'region',
    },
  },
  {
    name: 'b2b_point',
    parentName: 'b2b',
    list: B2bPointList,
    show: B2bPointShow,
    edit: B2bPointEdit,
    options: {
      label: 'Point',
      route: 'point',
    },
  },
].filter(envModuleFlagVault);
