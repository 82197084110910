import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_IMPORT */
const RegionDistrictsCreate = loadable(
  () => import(/* webpackChunkName: "RegionDistrictsCreate" */ 'pages/region/districts/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionDistrictsEdit = loadable(
  () => import(/* webpackChunkName: "RegionDistrictsEdit" */ 'pages/region/districts/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionDistrictsList = loadable(
  () => import(/* webpackChunkName: "RegionDistrictsList" */ 'pages/region/districts/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionDistrictsShow = loadable(
  () => import(/* webpackChunkName: "RegionDistrictsShow" */ 'pages/region/districts/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionPricingAreasCreate = loadable(
  () =>
    import(/* webpackChunkName: "RegionPricingAreasCreate" */ 'pages/region/pricingAreas/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionPricingAreasEdit = loadable(
  () => import(/* webpackChunkName: "RegionPricingAreasEdit" */ 'pages/region/pricingAreas/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionPricingAreasList = loadable(
  () => import(/* webpackChunkName: "RegionPricingAreasList" */ 'pages/region/pricingAreas/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionPricingAreasShow = loadable(
  () => import(/* webpackChunkName: "RegionPricingAreasShow" */ 'pages/region/pricingAreas/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionProvincesCreate = loadable(
  () => import(/* webpackChunkName: "RegionProvincesCreate" */ 'pages/region/provinces/create'),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionProvincesEdit = loadable(
  () => import(/* webpackChunkName: "RegionProvincesEdit" */ 'pages/region/provinces/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionProvincesList = loadable(
  () => import(/* webpackChunkName: "RegionProvincesList" */ 'pages/region/provinces/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionProvincesShow = loadable(
  () => import(/* webpackChunkName: "RegionProvincesShow" */ 'pages/region/provinces/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionRegenciesCreate = loadable(
  () => import(/* webpackChunkName: "RegionRegenciesCreate" */ 'pages/region/regencies/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionRegenciesEdit = loadable(
  () => import(/* webpackChunkName: "RegionRegenciesEdit" */ 'pages/region/regencies/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionRegenciesList = loadable(
  () => import(/* webpackChunkName: "RegionRegenciesList" */ 'pages/region/regencies/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionRegenciesShow = loadable(
  () => import(/* webpackChunkName: "RegionRegenciesShow" */ 'pages/region/regencies/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionSalesAreasCreate = loadable(
  () => import(/* webpackChunkName: "RegionSalesAreasCreate" */ 'pages/region/salesAreas/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionSalesAreasEdit = loadable(
  () => import(/* webpackChunkName: "RegionSalesAreasEdit" */ 'pages/region/salesAreas/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionSalesAreasList = loadable(
  () => import(/* webpackChunkName: "RegionSalesAreasList" */ 'pages/region/salesAreas/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionSalesAreasShow = loadable(
  () => import(/* webpackChunkName: "RegionSalesAreasShow" */ 'pages/region/salesAreas/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionSalesCoveragesCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "RegionSalesCoveragesCreate" */ 'pages/region/salesCoverages/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionSalesCoveragesEdit = loadable(
  () =>
    import(/* webpackChunkName: "RegionSalesCoveragesEdit" */ 'pages/region/salesCoverages/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionSalesCoveragesList = loadable(
  () =>
    import(/* webpackChunkName: "RegionSalesCoveragesList" */ 'pages/region/salesCoverages/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionSalesCoveragesShow = loadable(
  () =>
    import(/* webpackChunkName: "RegionSalesCoveragesShow" */ 'pages/region/salesCoverages/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const RegionVillagesCreate = loadable(
  () => import(/* webpackChunkName: "RegionVillagesCreate" */ 'pages/region/villages/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionVillagesEdit = loadable(
  () => import(/* webpackChunkName: "RegionVillagesEdit" */ 'pages/region/villages/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionVillagesList = loadable(
  () => import(/* webpackChunkName: "RegionVillagesList" */ 'pages/region/villages/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const RegionVillagesShow = loadable(
  () => import(/* webpackChunkName: "RegionVillagesShow" */ 'pages/region/villages/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const MapPinLine = loadable(() => import('@onefish/icons-react/src/icons/phosphor/MapPinLine'));

export const regionResources: ResourceProps[] = [
  {
    name: 'region',
    icon: <MapPinLine size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'region_villages',
    parentName: 'region',
    create: RegionVillagesCreate,
    edit: RegionVillagesEdit,
    list: RegionVillagesList,
    show: RegionVillagesShow,
    options: {
      label: 'Villages',
      route: 'villages',
    },
  },
  {
    name: 'region_districts',
    parentName: 'region',
    create: RegionDistrictsCreate,
    edit: RegionDistrictsEdit,
    list: RegionDistrictsList,
    show: RegionDistrictsShow,
    canDelete: true,
    options: {
      label: 'districts',
      route: 'districts',
    },
  },
  {
    name: 'region_regencies',
    parentName: 'region',
    create: RegionRegenciesCreate,
    edit: RegionRegenciesEdit,
    list: RegionRegenciesList,
    show: RegionRegenciesShow,
    canDelete: true,
    options: {
      label: 'regencies',
      route: 'regencies',
    },
  },
  {
    name: 'region_provinces',
    parentName: 'region',
    create: RegionProvincesCreate,
    edit: RegionProvincesEdit,
    list: RegionProvincesList,
    show: RegionProvincesShow,
    canDelete: true,
    options: {
      label: 'provinces',
      route: 'provinces',
    },
  },
  {
    name: 'region_pricing-areas',
    parentName: 'region',
    create: RegionPricingAreasCreate,
    edit: RegionPricingAreasEdit,
    list: RegionPricingAreasList,
    show: RegionPricingAreasShow,
    canDelete: true,
    options: {
      label: 'pricing areas',
      route: 'pricing-areas',
    },
  },
  {
    name: 'region_sales-areas',
    parentName: 'region',
    create: RegionSalesAreasCreate,
    edit: RegionSalesAreasEdit,
    list: RegionSalesAreasList,
    show: RegionSalesAreasShow,
    options: {
      label: 'sales areas',
      route: 'sales-areas',
    },
  },
  {
    name: 'region_sales-coverages',
    parentName: 'region',
    create: RegionSalesCoveragesCreate,
    edit: RegionSalesCoveragesEdit,
    list: RegionSalesCoveragesList,
    show: RegionSalesCoveragesShow,
    canDelete: true,
    options: {
      label: 'sales coverages',
      route: 'sales-coverages',
    },
  },
].filter(envModuleFlagVault);
