import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';
import { LoadingScreen } from 'components';

/* PLOP_INJECT_LOADABLE */
const DocsignList = loadable(
  () => import(/* webpackChunkName: "DocsignList" */ 'pages/digitalSign/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const DocsignShow = loadable(
  () => import(/* webpackChunkName: "DocsignShow" */ 'pages/digitalSign/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const PenNib = loadable(() => import('@onefish/icons-react/src/icons/phosphor/PenNib'));

export const digitalSignResources: ResourceProps[] = [
  {
    name: 'docsigns',
    icon: <PenNib size={16} />,
    list: DocsignList,
    show: DocsignShow,
    options: {
      label: 'Digital Sign',
    },
  },
].filter(envModuleFlagVault);
