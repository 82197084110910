export const MODULES = {
  /* PLOP_INJECT_ENABLE_MODULE */
  ENABLE_MODULE_B2B_SUPPLIER: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_B2B_SUPPLIER || '',
  ),
  ENABLE_MODULE_B2B_CUSTOMER: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_B2B_CUSTOMER || '',
  ),
  ENABLE_MODULE_B2B_REGION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_B2B_REGION || '',
  ),
  ENABLE_MODULE_B2B_POINT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_B2B_POINT || '',
  ),
  ENABLE_MODULE_B2B_SALES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_B2B_SALES || '',
  ),
  'ENABLE_MODULE_BUYER_LEAD-COMMODITIES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_BUYER_LEAD_COMMODITIES || '',
  ),
  'ENABLE_MODULE_BUYER_LEAD-OUTLETS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_BUYER_LEAD_OUTLETS || '',
  ),
  ENABLE_MODULE_BUYER_LEADS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_BUYER_LEADS || '',
  ),
  ENABLE_MODULE_CAMPAIGN_BUDGET: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CAMPAIGN_BUDGET || '',
  ),
  'ENABLE_MODULE_CAMPAIGN_CAMPAIGN-LINES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CAMPAIGN_CAMPAIGN_LINES || '',
  ),
  ENABLE_MODULE_CAMPAIGN_CAMPAIGNS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CAMPAIGN_CAMPAIGNS || '',
  ),
  'ENABLE_MODULE_CAMPAIGN_PROMO-BUYER': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CAMPAIGN_PROMO_BUYER || '',
  ),
  ENABLE_MODULE_CAMPAIGN_PROMO_FARMER: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CAMPAIGN_PROMO_FARMER || '',
  ),
  'ENABLE_MODULE_CORE_COMMODITY-CATEGORIES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CORE_COMMODITY_CATEGORIES || '',
  ),
  'ENABLE_MODULE_CORE_PRODUCT-TEMPLATES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CORE_PRODUCT_TEMPLATES || '',
  ),
  ENABLE_MODULE_CORE_PRODUCTS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_CORE_PRODUCTS || '',
  ),
  ENABLE_MODULE_DASHBOARD: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_DASHBOARD || '',
  ),
  ENABLE_MODULE_DOCSIGNS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_DOCSIGNS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_ACQUISITION-CHANNELS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_ACQUISITION_CHANNELS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_ADJUSTMENT-LABELS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_ADJUSTMENT_LABELS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_ADJUSTMENT-TYPES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_ADJUSTMENT_TYPES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_BUYER-LEAD-TERM-OF-PAYMENTS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_BUYER_LEAD_TERM_OF_PAYMENTS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_BUYER-LEAD-TIERS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_BUYER_LEAD_TIERS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_CANCELLATION-REASON': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_CANCELLATION_REASON || '',
  ),
  ENABLE_MODULE_ENUMERATION_CATEGORIES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_CATEGORIES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_COMMODITY-NAMES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_COMMODITY_NAMES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_COMPLAINT-PURPOSES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_COMPLAINT_PURPOSES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_COMPLAINT-TYPES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_COMPLAINT_TYPES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_CUSTOMER-CARE-ACTIVITY-RESPONSES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_CUSTOMER_CARE_ACTIVITY_RESPONSES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_CUSTOMER-CARE-ACTIVITY-TYPES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_CUSTOMER_CARE_ACTIVITY_TYPES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_DISTRIBUTION-CHANNELS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_DISTRIBUTION_CHANNELS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_FINAL-STATUSES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_FINAL_STATUSES || '',
  ),
  ENABLE_MODULE_ENUMERATION_GLAZES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_GLAZES || '',
  ),
  ENABLE_MODULE_ENUMERATION_GRADES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_GRADES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_JOB-TITLES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_JOB_TITLES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_NEXT-STEP-COORDINATIONS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_NEXT_STEP_COORDINATIONS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_ORDER-REJECTION-REASONS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_ORDER_REJECTION_REASONS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_ORDER-TYPES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_ORDER_TYPES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_PACKING-SIZES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_PACKING_SIZES || '',
  ),
  ENABLE_MODULE_ENUMERATION_PAY: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_PAY || '',
  ),
  'ENABLE_MODULE_ENUMERATION_PIC-INPUTS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_PIC_INPUTS || '',
  ),
  'ENABLE_MODULE_ENUMERATION_UNIT-INVENTORIES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_UNIT_INVENTORIES || '',
  ),
  'ENABLE_MODULE_ENUMERATION_UNIT-LOAD-DEVICE': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ENUMERATION_UNIT_LOAD_DEVICE || '',
  ),
  ENABLE_MODULE_FARMER_LEADS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_FARMER_LEADS || '',
  ),
  ENABLE_MODULE_FARMER_MPI: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_FARMER_MPI || '',
  ),
  ENABLE_MODULE_FARMER_PONDS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_FARMER_PONDS || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_HUB': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_HUB || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_INBOUND-MANAGEMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_INBOUND_MANAGEMENT || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_INBOUND-MANAGEMENT_INBOUND-REQUEST': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_INBOUND_MANAGEMENT_INBOUND_REQUEST || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_INBOUND-MANAGEMENT_PICKING-DOCUMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_INBOUND_MANAGEMENT_PICKING_DOCUMENT || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_OUTBOUND-MANAGEMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_OUTBOUND_MANAGEMENT || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_OUTBOUND-MANAGEMENT_OUTBOUND-DOCUMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_OUTBOUND_MANAGEMENT_OUTBOUND_DOCUMENT || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_OUTBOUND-MANAGEMENT_OUTBOUND-REQUEST': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_OUTBOUND_MANAGEMENT_OUTBOUND_REQUEST || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_PAYMENT-RECAP': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_PAYMENT_RECAP || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_STOCK-MANAGEMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_STOCK_MANAGEMENT || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_STOCK-MANAGEMENT_STOCK-ADJUSTMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_STOCK_MANAGEMENT_STOCK_ADJUSTMENT || '',
  ),
  'ENABLE_MODULE_HUB-MANAGEMENT_STOCK-MANAGEMENT_STOCK-DOCUMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_HUB_MANAGEMENT_STOCK_MANAGEMENT_STOCK_DOCUMENT || '',
  ),
  'ENABLE_MODULE_LOGISTIC_DELIVERY-ASSIGNMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_LOGISTIC_DELIVERY_ASSIGNMENT || '',
  ),
  'ENABLE_MODULE_LOGISTIC_DRIVER-MANAGEMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_LOGISTIC_DRIVER_MANAGEMENT || '',
  ),
  'ENABLE_MODULE_LOGISTIC_PICKUP-ASSIGNMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_LOGISTIC_PICKUP_ASSIGNMENT || '',
  ),
  'ENABLE_MODULE_LOGISTIC_SHUTTLE-ASSIGNMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_LOGISTIC_SHUTTLE_ASSIGNMENT || '',
  ),
  'ENABLE_MODULE_MERCHANDISER_PURCHASE-ORDER': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_MERCHANDISER_PURCHASE_ORDER || '',
  ),
  'ENABLE_MODULE_MERCHANDISER_PURCHASE-ORDER-V2': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_MERCHANDISER_PURCHASE_ORDER_V2 || '',
  ),
  'ENABLE_MODULE_MERCHANDISER_STOCK-CATALOG': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_MERCHANDISER_STOCK_CATALOG || '',
  ),
  'ENABLE_MODULE_PRICING_BASED-PRICE-FLB': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_PRICING_BASED_PRICE_FLB || '',
  ),
  'ENABLE_MODULE_PRICING_BASED-PRICES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_PRICING_BASED_PRICES || '',
  ),
  'ENABLE_MODULE_PRICING_BOTTOM-PRICES-DOWNSTREAM': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_PRICING_BOTTOM_PRICES_DOWNSTREAM || '',
  ),
  'ENABLE_MODULE_PRICING_FIRST-LEVEL-PRICES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_PRICING_FIRST_LEVEL_PRICES || '',
  ),
  'ENABLE_MODULE_PRICING_LOG-PUBLISHED-PRICES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_PRICING_LOG_PUBLISHED_PRICES || '',
  ),
  'ENABLE_MODULE_PRICING_PRICING-DATAS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_PRICING_PRICING_DATAS || '',
  ),
  'ENABLE_MODULE_PRICING_PUBLISHED-PRICES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_PRICING_PUBLISHED_PRICES || '',
  ),
  'ENABLE_MODULE_QUALITY-CONTROL_FIRST-MILES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_QUALITY_CONTROL_FIRST_MILES || '',
  ),
  'ENABLE_MODULE_QUALITY-CONTROL_LAST-MILES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_QUALITY_CONTROL_LAST_MILES || '',
  ),
  'ENABLE_MODULE_QUALITY-CONTROL_ORDER-EXTERNAL-REFERENCES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_QUALITY_CONTROL_ORDER_EXTERNAL_REFERENCES || '',
  ),
  'ENABLE_MODULE_QUALITY-CONTROL_STAFF': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_QUALITY_CONTROL_STAFF || '',
  ),
  ENABLE_MODULE_REGION_DISTRICTS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_REGION_DISTRICTS || '',
  ),
  'ENABLE_MODULE_REGION_PRICING-AREAS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_REGION_PRICING_AREAS || '',
  ),
  ENABLE_MODULE_REGION_PROVINCES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_REGION_PROVINCES || '',
  ),
  ENABLE_MODULE_REGION_REGENCIES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_REGION_REGENCIES || '',
  ),
  'ENABLE_MODULE_REGION_SALES-AREAS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_REGION_SALES_AREAS || '',
  ),
  'ENABLE_MODULE_REGION_SALES-COVERAGES': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_REGION_SALES_COVERAGES || '',
  ),
  ENABLE_MODULE_REGION_VILLAGES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_REGION_VILLAGES || '',
  ),
  'ENABLE_MODULE_ROUTE-MANAGEMENT_DELIVERY-ASSIGNMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ROUTE_MANAGEMENT_DELIVERY_ASSIGNMENT || '',
  ),
  'ENABLE_MODULE_ROUTE-MANAGEMENT_PICKUP-ASSIGNMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_ROUTE_MANAGEMENT_PICKUP_ASSIGNMENT || '',
  ),
  'ENABLE_MODULE_SALES_DOWNSTREAM-ORDERS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SALES_DOWNSTREAM_ORDERS || '',
  ),
  'ENABLE_MODULE_SALES_DOWNSTREAM-ORDERS-V2': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SALES_DOWNSTREAM_ORDERS_V2 || '',
  ),
  'ENABLE_MODULE_SALES_FISMO-REQUEST': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SALES_FISMO_REQUEST || '',
  ),
  ENABLE_MODULE_SALES_ORDERS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SALES_ORDERS || '',
  ),
  ENABLE_MODULE_SALES_QUOTES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SALES_QUOTES || '',
  ),
  'ENABLE_MODULE_SETTING_ACCESS-MANAGEMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_ACCESS_MANAGEMENT || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-AR-AP-SAMEDAY-PAYMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_AR_AP_SAMEDAY_PAYMENT || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-DIGITAL-SIGN': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_DIGITAL_SIGN || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-DROPSHIP': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_DROPSHIP || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-E-NOTA': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_E_NOTA || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-LAPAK-IKAN': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_LAPAK_IKAN || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-PAY-AR-KABAYAN': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_PAY_AR_KABAYAN || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-PAYMENT-VA': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_PAYMENT_VA || '',
  ),
  'ENABLE_MODULE_SETTING_AREA-DOWNSTREAM-APP': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_AREA_DOWNSTREAM_APP || '',
  ),
  'ENABLE_MODULE_SETTING_DEFAULT-PLAFON': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_DEFAULT_PLAFON || '',
  ),
  'ENABLE_MODULE_SETTING_DOWNSTREAM-DIGITAL-PAYMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_DOWNSTREAM_DIGITAL_PAYMENT || '',
  ),
  'ENABLE_MODULE_SETTING_LOGISTIC-FEE': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_LOGISTIC_FEE || '',
  ),
  ENABLE_MODULE_SETTING_REFERRALS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_REFERRALS || '',
  ),
  'ENABLE_MODULE_SETTING_SUPPLY-POINTS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_SUPPLY_POINTS || '',
  ),
  ENABLE_MODULE_SETTING_SUPPLYKU: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SETTING_SUPPLYKU || '',
  ),
  'ENABLE_MODULE_SUPPLY_AR-AP-SETTLEMENT': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_AR_AP_SETTLEMENT || '',
  ),
  'ENABLE_MODULE_SUPPLY_CONTRACT-STOCK': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_CONTRACT_STOCK || '',
  ),
  ENABLE_MODULE_SUPPLY_FLB: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_FLB || '',
  ),
  ENABLE_MODULE_SUPPLY_RMP: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_RMP || '',
  ),
  'ENABLE_MODULE_SUPPLY_STOCK-CARDS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_STOCK_CARDS || '',
  ),
  'ENABLE_MODULE_SUPPLY_STOCK-CARDS-DOWNSTREAM': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_STOCK_CARDS_DOWNSTREAM || '',
  ),
  'ENABLE_MODULE_SUPPLY_STOCK-CARDS-FLB': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_STOCK_CARDS_FLB || '',
  ),
  ENABLE_MODULE_SUPPLY_SUPPLIERS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SUPPLY_SUPPLIERS || '',
  ),
  'ENABLE_MODULE_SYSTEM_EVENT-LOGS': ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_SYSTEM_EVENT_LOGS || '',
  ),
  ENABLE_MODULE_USER_EXTENDS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MODULE_USER_EXTENDS || '',
  ),
};
