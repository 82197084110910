import loadable from '@loadable/component';
import { RefineRouteProps } from '@pankod/refine-react-router-v6';
import { LoadingScreen } from 'components';

const LogisticDriverManagementAttendanceCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDriverManagementAttendanceCreate" */ 'pages/logistic/driverManagement/attendance/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const LogisticDriverManagementAttendanceEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "LogisticDriverManagementAttendanceEdit" */ 'pages/logistic/driverManagement/attendance/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const attendanceRoutes: RefineRouteProps[] = [
  {
    element: <LogisticDriverManagementAttendanceCreate />,
    path: '/logistic/driver-management/show/:id/attendance',
    layout: true,
  },
  {
    element: <LogisticDriverManagementAttendanceEdit />,
    path: '/logistic/driver-management/show/:id/attendance/:idAttendance',
    layout: true,
  },
];

export default attendanceRoutes;
