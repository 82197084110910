import { ENV } from '@config/env';
import { Auth, Stage } from '@efishery/sdk-core';
import { AuthProvider } from '@pankod/refine-core';

export const auth = new Auth(ENV.AUTH_CLIENT_ID, Stage[ENV.APP_ENVIRONMENT as keyof typeof Stage], {
  enableCookieKeys: ENV.ENABLE_COOKIE_KEYS,
});

export const authProvider: AuthProvider = {
  login: async ({ grantType, code }) => {
    if (ENV.ENABLE_COOKIE_KEYS) {
      await auth.initializeCookieKeys();
    }

    const { accessToken } = await auth.persistExchange(code, grantType);
    await auth.getProfile(accessToken);

    const redirectTo = window.localStorage.getItem('to');

    if (redirectTo) {
      return Promise.resolve(redirectTo);
    }

    return Promise.resolve('/');
  },
  logout: async () => {
    await auth.logout();

    return Promise.resolve('/login');
  },
  checkAuth: async () => {
    const isLoggedIn = await auth.isSignedAsLogged();

    if (isLoggedIn) {
      return Promise.resolve();
    } else {
      return Promise.reject({ redirectTo: '/login' });
    }
  },
  checkError: () => Promise.resolve(),
  getPermissions: async () => {
    const accessToken = await auth.getAccessToken();
    const user = await auth.getProfile(accessToken as string);
    return Promise.resolve(user?.data?.permissions || []);
  },
  getUserIdentity: async () => {
    const accessToken = await auth.getAccessToken();
    const { data } = await auth.getProfile(accessToken as string);
    return {
      ...data,
      name: data?.full_name,
      avatar: null,
    };
  },
};
