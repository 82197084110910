import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';
import { TitleProps } from '@pankod/refine-core';

import { ReactComponent as Logo } from 'assets/images/efishery.svg';
import { ReactComponent as Octo } from 'assets/images/octo.svg';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link
    to="/"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {collapsed ? (
      <Logo
        style={{
          fill: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '60px',
          padding: '12px 24px',
        }}
      />
    ) : (
      <Octo
        style={{
          fill: 'white',
          height: '60px',
          width: '200px',
          padding: '12px 8px',
          marginTop: '10px',
        }}
      />
    )}
  </Link>
);
