import { useCustom } from '@pankod/refine-core';
import { SERVICE } from 'config/service';
import { useUserIdentity } from './useUserIdentity';

type FlaggingOptions = {
  flagId: number;
  entityId?: string;
  entityType?: string;
  entityContext?: Record<string, unknown>;
};

export const useFlagging = ({ flagId, entityId, entityType, entityContext }: FlaggingOptions) => {
  const { userCountry } = useUserIdentity();

  if (!flagId) {
    throw new Error('Invalid flag ID');
  }

  if (!entityType) {
    entityType = 'feature';
  }

  const evaluation = useCustom({
    url: `${SERVICE.FLAGGING}/api/v1/evaluation`,
    method: 'post',
    config: {
      payload: {
        entityID: entityId,
        flagID: flagId,
        entityType,
        entityContext,
      },
    },
    queryOptions: {
      select: (data) => ({ data }),
    },
  });

  if (evaluation.data?.data?.variantAttachment?.excludes?.includes(userCountry)) {
    return {
      ...evaluation.data,
      data: {
        ...evaluation.data?.data,
        variantKey: 'off',
      },
    };
  }

  return evaluation.data;
};
