import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

const FarmerLeadsList = loadable(
  () => import(/* webpackChunkName: "FarmerLeadsList" */ 'pages/farmer/leads/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const FarmerLeadsShow = loadable(
  () => import(/* webpackChunkName: "FarmerLeadsShow" */ 'pages/farmer/leads/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const FarmerLeadsMPIList = loadable(
  () => import(/* webpackChunkName: "FarmerLeadsMPIList" */ 'pages/farmer/mpi/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const FarmerPondsList = loadable(
  () => import(/* webpackChunkName: "FarmerPondsList" */ 'pages/farmer/ponds/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const FarmerPondsShow = loadable(
  () => import(/* webpackChunkName: "FarmerPondsShow" */ 'pages/farmer/ponds/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const FarmerPondsEdit = loadable(
  () => import(/* webpackChunkName: "FarmerPondsEdit" */ 'pages/farmer/ponds/edit'),
  {
    fallback: <LoadingScreen />,
  },
);

const PetaniTopiCaping = loadable(
  () => import('@onefish/icons-react/src/icons/efishery/PetaniTopiCaping'),
);

export const farmerResources: ResourceProps[] = [
  {
    name: 'farmer',
    icon: <PetaniTopiCaping size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'farmer_leads',
    parentName: 'farmer',
    list: FarmerLeadsList,
    show: FarmerLeadsShow,
    options: {
      label: 'leads',
      route: 'leads',
    },
  },
  {
    name: 'farmer_mpi',
    parentName: 'farmer',
    list: FarmerLeadsMPIList,
    options: {
      label: 'Leads MPI',
      route: 'mpi',
    },
  },
  {
    name: 'farmer_ponds',
    parentName: 'farmer',
    edit: FarmerPondsEdit,
    list: FarmerPondsList,
    show: FarmerPondsShow,
    options: {
      label: 'Ponds',
      route: 'ponds',
    },
  },
].filter(envModuleFlagVault);
