interface ILocale {
  code: number;
  currency: string;
  currencySymbol: string;
  label: string;
  language: string;
  numberFormat: string;
}

export type CountriesType = 'indonesia' | 'india' | string;

const localeOptions: {
  [key in CountriesType]: ILocale;
} = {
  indonesia: {
    code: 62,
    currency: 'IDR',
    currencySymbol: 'Rp.',
    label: 'Indonesia',
    language: 'id',
    numberFormat: 'id-ID',
  },
  india: {
    code: 91,
    currency: 'INR',
    currencySymbol: '₹',
    label: 'India',
    language: 'en',
    numberFormat: 'en-IN',
  },
};

const supportedLocales = [localeOptions.indonesia.language, localeOptions.india.language];
const defaultLocale = localeOptions.indonesia.language;
const detectLocaleKey = 'lang';

export { supportedLocales, defaultLocale, detectLocaleKey, localeOptions };
