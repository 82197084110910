import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Spin } from '@pankod/refine-antd';

export const LoadingScreen = () => (
  <Row justify="center" align="middle" style={{ height: '100vh' }}>
    <Col>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
    </Col>
  </Row>
);
