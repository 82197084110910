import React from 'react';
import { useGetIdentity } from '@pankod/refine-core';
import { AntdLayout, Typography, Space, Avatar, Button } from '@pankod/refine-antd';
import { useKBar } from 'kbar';
import loadable from '@loadable/component';

import { isUserV2 } from 'libs/helpers';
import type { IUser, IUserV1 } from 'interfaces';
import { useMediaQuery } from 'hooks';

const MagnifyingGlass = loadable(
  () => import('@onefish/icons-react/src/icons/phosphor/MagnifyingGlass'),
);

const { Text } = Typography;

export const Header: React.FC = () => {
  const { query } = useKBar();
  const { data: user } = useGetIdentity<IUser | IUserV1>();
  const size = useMediaQuery();

  const isMobile = ['sm', 'xs'].includes(size);

  const userName = user && isUserV2(user) ? user?.full_name : user?.name;
  const shouldRenderHeader = user && userName;

  return shouldRenderHeader ? (
    <AntdLayout.Header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px',
        backgroundColor: '#FFF',
        boxShadow: '0 1px 4px rgb(0 21 41 / 8%)',
        zIndex: 19,
      }}
    >
      <SearchButton onClick={query.toggle} isMobile={isMobile} />
      <Space>
        {userName && (
          <Text ellipsis style={{ fontWeight: 500 }}>
            {userName}
          </Text>
        )}
        <Avatar
          src={user?.avatar}
          alt={userName}
          crossOrigin=""
          style={{ backgroundColor: '#F59E0B' }}
        >
          {userName.charAt(0)}
        </Avatar>
      </Space>
    </AntdLayout.Header>
  ) : null;
};

type SearchButtonProps = {
  onClick: () => void;
  isMobile: boolean;
};

const SearchButton = ({ onClick, isMobile }: SearchButtonProps) => (
  <Button
    onClick={onClick}
    style={{
      display: 'flex',
      paddingRight: isMobile ? '12px' : '4px',
      minWidth: isMobile ? 'auto' : '300px',
    }}
  >
    <></>
    {isMobile ? (
      <MagnifyingGlass size="18" />
    ) : (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text type="secondary">Search Menu...</Text>
        <Text type="secondary">
          <kbd
            style={{
              padding: '2px 4px',
              backgroundColor: '#eee',
              borderRadius: '4px',
              zIndex: 0,
            }}
          >
            ⌘/ctrl + K
          </kbd>
        </Text>
      </div>
    )}
  </Button>
);
