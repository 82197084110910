import loadable from '@loadable/component';
import { RefineRouteProps } from '@pankod/refine-react-router-v6';
import { LoadingScreen } from 'components';

const QualityControlFirstMilesReview = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlFirstMilesReview" */ 'pages/qualityControl/firstMiles/review'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const QualityControlLastMilesReview = loadable(
  () =>
    import(
      /* webpackChunkName: "QualityControlLastMilesReview" */ 'pages/qualityControl/lastMiles/review'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const MerchandiserStockCatalogReviewCL = loadable(
  () =>
    import(
      /* webpackChunkName: "MerchandiserStockCatalogReviewCL" */ 'pages/merchandiser/stockCatalog/review'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

const reviewRoutes: RefineRouteProps[] = [
  {
    element: <QualityControlFirstMilesReview />,
    path: '/quality-control/first-miles/review/:id',
    layout: true,
  },
  {
    element: <QualityControlLastMilesReview />,
    path: '/quality-control/last-miles/review/:id',
    layout: true,
  },
  {
    element: <MerchandiserStockCatalogReviewCL />,
    path: '/merchandiser/stock-catalog/review/:id',
    layout: true,
  },
];

export default reviewRoutes;
